export default {
    url: {
        reco: 'https://api.kredo.in:5080/api',
        ums: 'https://api.kredo.in:5000',
        ui: 'https://refactoring.d207goeuglm4vc.amplifyapp.com',
        gstReco: 'https://api.kredo.in:5081/gst',
        gstApi: 'https://api.kredo.in:5081',
        poso: 'https://api.kredo.in:5082'
    }
};
