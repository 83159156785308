// React imports
import React, { useState, useEffect } from 'react';

// React router dom imports
import { useLocation, Link as RouterLink } from 'react-router-dom';

// Redux imports
import { useDispatch } from 'react-redux';

// Material ui imports
// Components
import { makeStyles, useTheme } from '@material-ui/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AuthWrapper2 from 'views/pages/authentication/AuthWrapper2';
import AuthCardWrapper from 'views/pages/authentication/AuthCardWrapper';
import AuthSlider from 'ui-component/cards/AuthSlider';
import AuthFooter from 'ui-component/cards/AuthFooter';
import { Box, Grid, useMediaQuery } from '@material-ui/core';

// User imports
// Relative imports
import { getLatestOutputFile } from '../ProjectHome/requestHelper';
import { getReconciliationDetails } from './requestHelper';
// Absolute imports
import { SNACKBAR_OPEN } from 'store/actions';
import BackgroundPattern2 from 'ui-component/cards/BackgroundPattern2';

// assets
import imgMain from 'assets/images/auth/img-a2-checkmail.svg';
import Logo from 'assets/images/icons/logo.png';

const useStyles = makeStyles({
    authImage: {
        maxWidth: '100%',
        margin: '0 auto',
        display: 'block',
        width: '300px'
    }
});

const items = [
    {
        title: 'Cloud-enabled automated ledger reconciliation',
        description: ''
    },
    {
        title: 'Realtime collaboration and Automated mailers/reminders to drive faster closures',
        description: ''
    },
    {
        title: 'Multiple dashboards and reports for management',
        description: ''
    }
];

const DownloadOutputFile = () => {
    const classes = useStyles();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

    const queryParams = new URLSearchParams(useLocation().search);
    const reco_iteration_id = queryParams.get('reco_iteration_id');
    const stateId = queryParams.get('stateId');
    const recoName = queryParams.get('recoName');
    const [data, setData] = useState({
        companyName: '',
        fromDate: null,
        toDate: null
    });

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const showSnackBar = (msg, isError = false) => {
        if (!isError) {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: msg,
                variant: 'alert',
                alertSeverity: 'success',
                close: true
            });
        } else {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: msg,
                variant: 'alert',
                alertSeverity: 'error',
                close: true
            });
        }
    };

    const isPartyUploadAllowed = () => {
        const params = {
            state_id: stateId
        };
        getReconciliationDetails(params).then((response) => {
            setData({
                companyName: response.data.company_name,
                fromDate: new Date(response.data.from_date),
                toDate: new Date(response.data.to_date)
            });
        });
    };

    const awsDownloadHelper = (downloadUrl) => {
        const URL = window.URL || window.webkitURL;

        // use HTML5 a[download] attribute to specify filename
        const a = document.createElement('a');
        // safari doesn't support this yet
        if (typeof a.download === 'undefined') {
            window.location.href = downloadUrl;
        } else {
            a.href = downloadUrl;
            document.body.appendChild(a);
            a.click();
        }

        setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
        }, 100); // cleanup
    };

    const downloadLatestOutputFile = async () => {
        setIsLoading(true);
        const response = await getLatestOutputFile(reco_iteration_id);
        if (!response.data) {
            showSnackBar('File not found', true);
            setIsLoading(false);
            return;
        }
        const { data } = response;
        const downloadUrl = data.modified_file || data.output_file;
        if (!downloadUrl) {
            showSnackBar('File not found', true);
            setIsLoading(false);
            return;
        }
        awsDownloadHelper(downloadUrl);
        setIsLoading(false);
    };

    useEffect(() => {
        isPartyUploadAllowed();
    }, []);

    return (
        <AuthWrapper2>
            <Grid container justifyContent={matchDownSM ? 'center' : 'space-between'} alignItems="center">
                <Grid item md={6} lg={7} xs={12} sx={{ minHeight: '100vh' }}>
                    <Grid
                        sx={{ minHeight: '100vh' }}
                        container
                        alignItems={matchDownSM ? 'center' : 'flex-start'}
                        justifyContent={matchDownSM ? 'center' : 'space-between'}
                    >
                        <Grid item sx={{ display: { xs: 'none', md: 'block' }, m: 3 }}>
                            <RouterLink to="#">
                                <img src={Logo} style={{ width: '200px', height: '1.9rem' }} alt="Kredo Logo" />
                            </RouterLink>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            justifyContent="center"
                            alignItems="center"
                            sx={{ minHeight: { xs: 'calc(100vh - 68px)', md: 'calc(100vh - 152px)' } }}
                        >
                            <Stack justifyContent="center" alignItems="center" spacing={5} m={2}>
                                <Box component={RouterLink} to="#" sx={{ display: { xs: 'block', md: 'none' } }}>
                                    <img src={Logo} style={{ width: '200px', height: '1.9rem' }} alt="Kredo Logo" />
                                </Box>
                                <AuthCardWrapper border={matchDownMD}>
                                    <Grid container spacing={2} justifyContent="center">
                                        <Grid item xs={12}>
                                            <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                <Typography fontSize="16px" textAlign="center">
                                                    {data.companyName &&
                                                        data.fromDate &&
                                                        data.toDate &&
                                                        `${data.companyName} has performed reconciliation for the period ${new Date(
                                                            data.fromDate
                                                        ).toLocaleDateString('en-GB', {
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: 'numeric'
                                                        })} to ${new Date(data.toDate).toLocaleDateString('en-GB', {
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: 'numeric'
                                                        })}.`}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                <Typography variant="caption" fontSize="16px" textAlign="center">
                                                    To access the file, click on download button
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                variant="contained"
                                                color="secondary"
                                                component="span"
                                                onClick={downloadLatestOutputFile}
                                            >
                                                Download
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </AuthCardWrapper>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sx={{ m: 3 }}>
                            <AuthFooter />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6} lg={5} sx={{ position: 'relative', alignSelf: 'stretch', display: { xs: 'none', md: 'block' } }}>
                    <BackgroundPattern2>
                        <Grid item container justifyContent="center">
                            <Grid item xs={12}>
                                <Grid item container justifyContent="center" sx={{ pb: 8 }}>
                                    <Grid item xs={10} lg={8} sx={{ '& .slick-list': { pb: 2 } }}>
                                        <AuthSlider items={items} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <img alt="Auth method" src={imgMain} className={classes.authImage} />
                            </Grid>
                        </Grid>
                    </BackgroundPattern2>
                </Grid>
            </Grid>
        </AuthWrapper2>
    );
};

export default DownloadOutputFile;
