import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import PropTypes from 'prop-types';
import { Collapse, IconButton, TableCell, TableRow, Typography, Grid, TableContainer, ButtonGroup, Tooltip } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { IconPlus, IconFileInvoice, IconNotebook } from '@tabler/icons';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getInvoicesReceived, getInvoices } from '../NetworkCalls/Invoice';
import { getPOItems, getPOReceivedItems, acceptReceivedPo, rejectReceivedPo } from '../NetworkCalls/PO';
import SubCard from 'ui-component/cards/SubCard';
import PoListItem from './PoListItem';
import InvoiceListItem from './InvoiceListItem';
import { getCurrentUser, getPOSOSchema } from '../NetworkCalls/UMS';
import RejectModal from '../RejectModel';

const POItem = ({ po: poProp, sales }) => {
    const theme = useTheme();
    const { companyId } = useParams();
    const [po, setPo] = useState(poProp);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [poItemsOpen, setPOItemsOpen] = useState(false);
    const [poItemsList, setPoItemList] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [user, setUser] = useState();
    const [database, setDatabase] = useState();
    const [openReject, setRejectOpen] = useState(false);

    useEffect(() => {
        (async () => {
            setUser(await getCurrentUser());
            const response = await getPOSOSchema(companyId);
            setDatabase(response.database);
        })();
    }, []);

    const fetchPurchaseItems = async () => {
        setPoItemList(JSON.parse(poProp.po_items));
        const invoicesReceived = await getInvoicesReceived(po.po_id);
        setInvoices(invoicesReceived);
    };
    const fetchSalesItems = async () => {
        setPoItemList(JSON.parse(poProp.po_items));
        const prevInvoices = await getInvoices(po.sales_po_id);
        setInvoices(prevInvoices);
    };

    const handleOpen = async () => {
        if (!open) {
            if (sales) {
                await fetchSalesItems();
            } else {
                await fetchPurchaseItems();
            }
        }
        setOpen(!open);
    };

    const handleRejectPo = async (remarks) => {
        const headers = {
            'customer-schema': po.customer_schema.toLowerCase(),
            'vendor-schema': po.vendor_schema.toLowerCase()
        };
        const params = {
            po_id: po.po_id,
            po_received_id: po.po_received_id,
            user_id: user.user_id
        };
        await rejectReceivedPo(headers, params, { reject_remarks: remarks });
        if (sales) {
            setPo({ ...po, po_status_master_id: 10, po_status: 'Vendor Rejected' });
        }
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Rejected PO',
            variant: 'alert',
            alertSeverity: 'warning'
        });
    };

    const handleAcceptPo = async () => {
        const headers = {
            'customer-schema': po.customer_schema.toLowerCase(),
            'vendor-schema': po.vendor_schema.toLowerCase()
        };
        const params = {
            sales_po_id: po.spi_sales_po_id,
            user_id: user.user_id
        };
        await acceptReceivedPo(headers, params, {});
        if (sales) {
            setPo({ ...po, po_status_master_id: 3, po_status: 'Raised' });
        }
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Updated Successfully',
            variant: 'alert',
            alertSeverity: 'success'
        });
    };

    return (
        <>
            <TableRow hover key={po.po_id} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell sx={{ minWidth: 24, maxWidth: 24 }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            handleOpen(open);
                        }}
                        sx={{ padding: 0 }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <RouterLink
                        to={
                            `/dashboard/default/${companyId}/poso/` +
                            `${sales ? 'view-received-po' : 'view-po'}/${sales ? po.sales_po_id : po.po_id}`
                        }
                        state={{
                            customerSchema: po.customer_schema,
                            database,
                            vendorSchema: po.vendor_schema,
                            sales,
                            id: sales ? po.po_received_id : po.po_id
                        }}
                    >
                        <Grid container direction="row" alignItems="center" justifyContent="flex-start">
                            <IconNotebook fontSize="small" color="purple" size="1.2rem" />
                            <Typography>{po.po_number}</Typography>
                        </Grid>
                    </RouterLink>
                </TableCell>
                <TableCell>{sales ? po.customer_company_name : po.vendor_company_name}</TableCell>
                <TableCell>
                    {new Date(po.po_date).toLocaleDateString('en-IN', { day: '2-digit', month: 'short', year: 'numeric' })}
                </TableCell>
                <TableCell align="right">
                    {(po.po_amount * po.exchange_rate).toFixed(0).replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',')}
                </TableCell>
                <TableCell>
                    <Typography>{po.description}</Typography>
                </TableCell>
                {sales && (
                    <TableCell>
                        <ButtonGroup disableElevation variant="contained" aria-label="accept reject buttons">
                            <Tooltip title="Accept PO" arrow>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    aria-label="accept po"
                                    onClick={handleAcceptPo}
                                    disabled={!po.is_accept_allowed}
                                >
                                    <DoneIcon color={po.is_accept_allowed ? 'success' : '#c5cae9'} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Reject PO" arrow>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    aria-label="accept po"
                                    onClick={() => {
                                        setRejectOpen(true);
                                    }}
                                    disabled={!po.is_reject_allowed}
                                >
                                    <CloseIcon color={po.is_reject_allowed ? 'error' : '#c5cae9'} />
                                </IconButton>
                            </Tooltip>
                            <RejectModal open={openReject} setOpen={setRejectOpen} handleReject={handleRejectPo} />
                            <Tooltip title="Add Invoice" arrow>
                                <RouterLink
                                    to={`${window.location.pathname.slice(0, window.location.pathname.search('poso'))}/poso/AddInvoice/${
                                        po.spi_sales_po_id
                                    }`}
                                    state={{ poReceived: poProp }}
                                >
                                    <IconButton disabled={!poProp.is_invoice_create_allowed} variant="contained" color="secondary">
                                        <IconFileInvoice size="1rem" />
                                        <IconPlus size="1rem" />
                                    </IconButton>
                                </RouterLink>
                            </Tooltip>
                        </ButtonGroup>
                    </TableCell>
                )}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <TableContainer>
                            {poItemsList.length > 0 && (
                                <SubCard
                                    sx={{
                                        bgcolor: theme.palette.mode === 'dark' ? 'dark.800' : '#7771'
                                    }}
                                    title="PO Items"
                                    content={false}
                                    secondary={
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => {
                                                setPOItemsOpen(!poItemsOpen);
                                            }}
                                            sx={{ padding: 0 }}
                                        >
                                            {poItemsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    }
                                >
                                    {poItemsOpen && <PoListItem poList={poItemsList} exchangeRate={po.exchange_rate} isSales={sales} />}
                                </SubCard>
                            )}
                        </TableContainer>
                    </Collapse>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <TableContainer>
                            {invoices.length > 0 && (
                                <SubCard
                                    sx={{
                                        bgcolor: theme.palette.mode === 'dark' ? 'dark.800' : '#7771'
                                    }}
                                    title="Invoices"
                                    content={false}
                                >
                                    <InvoiceListItem
                                        companyId={companyId}
                                        invoices={invoices}
                                        poId={sales ? po.po_received_id : po.po_id}
                                        isSales={sales}
                                        exchangeRate={po.exchange_rate}
                                    />
                                </SubCard>
                            )}
                        </TableContainer>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

POItem.propTypes = {
    po: PropTypes.object,
    sales: PropTypes.bool
};
export default POItem;
