export default {
    url: {
        reco: 'https://api.kredo.in:8080/api',
        ums: 'https://api.kredo.in:8000',
        ui: 'https://apps.kredo.in',
        gstReco: 'https://api.kredo.in:8081/gst',
        gstApi: 'https://api.kredo.in:8081',
        poso: 'https://api.kredo.in:8082'
    }
};
