import React, { useEffect, useState } from 'react';
// router
import { Link as RouterLink } from 'react-router-dom';
// props
import PropTypes from 'prop-types';
// Icons
import AddIcon from '@mui/icons-material/Add';
import { IconFileInvoice } from '@tabler/icons';
import MainCard from 'ui-component/cards/MainCard';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Tooltip } from '@mui/material';

// Network calls
import POItem from './PoItem';
import { UseGetPos, UseGetReceivedPos } from '../NetworkCalls/ReusableAPIHooks/PO';

const SOColumns = [
    { title: 'PO number' },
    { title: 'Customer' },
    { title: 'Date' },
    { title: 'Amount', align: 'right' },
    { title: 'Status' },
    { title: 'Actions' }
];
const POColumns = [
    { title: 'PO number' },
    { title: 'Vendor' },
    { title: 'Date' },
    { title: 'Amount', align: 'right' },
    { title: 'Status' }
];

const TransactionTable = ({ title, header }) => {
    const [isSales, setIsSales] = useState(true);
    let rows;
    if (title === 'Purchases') {
        const { data } = UseGetPos({ page: 1, size: 10 });
        rows = data?.rows;
    } else {
        const { data } = UseGetReceivedPos({ page: 1, size: 10 });
        rows = data?.rows;
    }

    useEffect(() => {
        (async () => {
            if (title === 'Purchases') {
                setIsSales(false);
            }
        })();
    }, [title]);

    return (
        <MainCard
            content={false}
            title={title}
            secondary={
                title === 'Purchases' && (
                    <RouterLink to={`${window.location.pathname.split('/').slice(0, -1).join('/')}/AddPo`}>
                        <Button variant="contained" size="small" color="secondary" endIcon={<AddIcon />}>
                            <IconFileInvoice />
                        </Button>
                    </RouterLink>
                )
            }
        >
            <TableContainer>
                <Table aria-label="collapsible table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            {title === 'Purchases'
                                ? POColumns.map((each, index) => (
                                      <TableCell key={index} variant="head" align={each.align ? each.align : 'inherit'}>
                                          {each.title}
                                      </TableCell>
                                  ))
                                : SOColumns.map((each, index) => (
                                      <TableCell key={index} variant="head" align={each.align ? each.align : 'inherit'}>
                                          {each.title}
                                      </TableCell>
                                  ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {rows?.map((po, index) => (
                            <POItem key={index} po={po} sales={isSales} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </MainCard>
    );
};
export default TransactionTable;

TransactionTable.propTypes = {
    title: PropTypes.string,
    header: PropTypes.object
};
