// action - state management
import { APPS } from './actions';

const firstState = {
    appShown: false
};

// ===========================|| APPS REDUCER ||=========================== //

const appsReducer = (state = firstState, action) => {
    switch (action.type) {
        case APPS:
            return {
                appShown: action.appShown ? action.appShown : firstState.appShown
            };
        default:
            return state;
    }
};

export default appsReducer;
