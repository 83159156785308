// import { FormattedMessage } from 'react-intl';
import { IconDashboard, IconDeviceAnalytics, IconFileInvoice } from '@tabler/icons';
import TimelineIcon from '@mui/icons-material/Timeline';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DashboardIcon from '@mui/icons-material/Dashboard';

const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    TimelineIcon,
    SummarizeIcon,
    ArticleIcon,
    SettingsIcon,
    AccountBalanceWalletIcon,
    DashboardIcon,
    IconFileInvoice
};

const POSO_PATH = window.location.pathname.slice(0, window.location.pathname.search('poso'));

const DashBoard = {
    id: 'dashboard',
    type: 'group',
    children: [
        {
            id: 'Dashboard',
            title: 'Recent',
            type: 'item',
            icon: icons.DashboardIcon,
            url: `${POSO_PATH}poso/DashBoard`,
            breadcrumbs: false
        },
        {
            id: 'purchases',
            title: 'Purchases',
            type: 'collapse',
            icon: icons.IconDashboard,
            children: [
                {
                    id: 'purchaseOrder',
                    title: 'Purchase Orders',
                    type: 'item',
                    url: `${POSO_PATH}poso/po`,
                    breadcrumbs: true
                },
                {
                    id: 'purchaseInvoice',
                    title: 'Invoices',
                    type: 'item',
                    url: `${POSO_PATH}poso/purchase/po/invoice`,
                    breadcrumbs: true
                },
                {
                    id: 'advance',
                    title: 'Advance',
                    type: 'item',
                    url: `${POSO_PATH}poso/purchase/po/advance`,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'sales',
            title: 'Sales',
            type: 'collapse',
            icon: icons.ArticleIcon,
            children: [
                {
                    id: 'saleseOrder',
                    title: 'Sales Orders',
                    type: 'item',
                    url: `${POSO_PATH}poso/sales`,
                    breadcrumbs: true
                },
                {
                    id: 'salesInvoice',
                    title: 'Invoices',
                    type: 'item',
                    url: `${POSO_PATH}poso/sales/invoice`,
                    breadcrumbs: true
                },
                {
                    id: 'collection',
                    title: 'Collection',
                    type: 'item',
                    url: `${POSO_PATH}poso/sales/collection`,
                    breadcrumbs: true
                }
            ]
        }
        //     {
        //         id: 'advance',
        //         title: 'Advance',
        //         type: 'collapse',
        //         icon: icons.IconFileInvoice,
        //         children: [
        //             {
        //                 id: 'raisedInvoice',
        //                 title: 'Raised Invoice',
        //                 type: 'item',
        //                 url: `${POSO_PATH}poso/invoice/raised`,
        //                 breadcrumbs: false
        //             },
        //             {
        //                 id: 'receivedInvoice',
        //                 title: 'Purchase Invoice',
        //                 type: 'item',
        //                 url: `${POSO_PATH}poso/invoice/received`,
        //                 breadcrumbs: false
        //             },
        //             {
        //                 id: 'approveInvoice',
        //                 title: 'Approve Invoice',
        //                 type: 'item',
        //                 url: `${POSO_PATH}poso/invoice/approve`,
        //                 breadcrumbs: false
        //             }
        //         ]
        //     },
        //     {
        //         id: 'payments',
        //         title: 'Finances',
        //         type: 'collapse',
        //         icon: icons.AccountBalanceWalletIcon,
        //         children: [
        //             {
        //                 id: 'Payable',
        //                 title: 'Payments',
        //                 type: 'item',
        //                 url: `${POSO_PATH}poso/tables/payable`,
        //                 breadcrumbs: false
        //             },
        //             {
        //                 id: 'receivedpayments',
        //                 title: 'Collections',
        //                 type: 'item',
        //                 url: `${POSO_PATH}poso/payments/received`,
        //                 breadcrumbs: false
        //             },
        //             {
        //                 id: 'Advance Payments',
        //                 title: 'Advance',
        //                 type: 'item',
        //                 url: `${POSO_PATH}poso/payments/advance`,
        //                 breadcrumbs: false
        //             }
        //         ]
        //     },
        //     {
        //         id: 'chart of accounts',
        //         title: 'Chart of accounts',
        //         type: 'item',
        //         icon: icons.ArticleIcon,
        //         url: `${POSO_PATH}poso/ChatOfAccounts`,
        //         breadcrumbs: false
        //     },
        //     {
        //         id: 'reports',
        //         title: 'Reports',
        //         type: 'collapse',
        //         icon: icons.ArticleIcon,
        //         children: [
        //             {
        //                 id: 'reports1',
        //                 title: 'PO register',
        //                 type: 'item',
        //                 url: `${POSO_PATH}poso/reports/report-1`,
        //                 breadcrumbs: false
        //             },
        //             {
        //                 id: 'reports2',
        //                 title: 'PO pricing tracker',
        //                 type: 'item',
        //                 url: `${POSO_PATH}poso/reports/report-2`,
        //                 breadcrumbs: false
        //             }
        //         ]
        //     },
        //     {
        //         id: 'Settings',
        //         title: 'Settings',
        //         type: 'item',
        //         icon: icons.SettingsIcon,
        //         url: `${POSO_PATH}poso/settings`,
        //         breadcrumbs: false
        //     }
    ]
};
export default DashBoard;
