import React, { useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box } from '@mui/material';

import PropTypes from 'prop-types';

const RejectModal = (props) => {
    const { open, setOpen, handleReject, invoice } = props;
    const [remarks, setRemarks] = useState('');

    const handleClose = () => {
        setOpen(false);
    };

    const handleRemarks = async () => {
        await handleReject(invoice, remarks);
        setOpen(false);
    };

    return (
        <Box>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>Reject Remarks</DialogTitle>
                <DialogContent>
                    <DialogContentText>Enter remarks</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="remarks"
                        label="Remarks"
                        placeholder="Write your remarks"
                        fullWidth
                        value={remarks}
                        variant="standard"
                        onChange={(e) => {
                            setRemarks(e.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleRemarks}
                        to={`${window.location.pathname.slice(0, window.location.pathname.search('poso'))}/poso/invoice/approve`}
                        component={RouterLink}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

RejectModal.propTypes = {
    open: PropTypes.bool,
    invoice: PropTypes.object,
    setOpen: PropTypes.func,
    handleReject: PropTypes.func
};
export default RejectModal;
