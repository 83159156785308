import React from 'react';

// Material UI imports
import { useTheme } from '@mui/material/styles';
import { Box, Button, LinearProgress } from '@mui/material';
import { DataGrid, GridToolbarExport, GridToolbarContainer } from '@mui/x-data-grid';

import PropTypes from 'prop-types';

// Project imports
import MainCard from 'ui-component/cards/MainCard';

function MyExportButton() {
    return (
        <Button size="small">
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        </Button>
    );
}

const CustomTableDataGrid = ({ rows, columns, isLoading, title, identifier }) => {
    const theme = useTheme();

    return (
        <MainCard content={false} title={title}>
            <Box
                sx={{
                    height: 500,
                    width: '100%',
                    '& .MuiDataGrid-root': {
                        border: 'none',
                        '& .MuiDataGrid-cell': {
                            borderColor: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
                        },
                        '& .MuiDataGrid-columnsContainer': {
                            color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
                            borderColor: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
                        },
                        '& .MuiDataGrid-columnSeparator': {
                            color: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
                        }
                    }
                }}
            >
                {!isLoading ? (
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        density="compact"
                        getRowId={(row) => row[identifier]}
                        components={{
                            Toolbar: MyExportButton
                        }}
                    />
                ) : (
                    <LinearProgress color="secondary" />
                )}
            </Box>
        </MainCard>
    );
};

CustomTableDataGrid.propTypes = {
    rows: PropTypes.array,
    columns: PropTypes.array,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    identifier: PropTypes.string
};

export default CustomTableDataGrid;
