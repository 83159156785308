import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
// import CustomLayout from 'layout/CustomLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const AdminPage = Loadable(lazy(() => import('views/dashboard/AdminDashboard/Companies')));
const ToolsPage = Loadable(lazy(() => import('views/dashboard/AdminDashboard/Tools')));

// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = {
    path: '/admin',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <AdminPage />
        },
        {
            path: '/companies',
            element: <AdminPage />
        },
        {
            path: '/tools',
            element: <ToolsPage />
        }
    ]
};

export default MainRoutes;
