// import Dashboardpo from './Dashboardpo';
import DashBoard from './DashBoard';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [DashBoard]
};

export default menuItems;
