import React, { useEffect } from 'react';
import axios from 'axios';
import { IconSwimming, IconSearch } from '@tabler/icons';
import { makeStyles, useTheme } from '@material-ui/styles';
import { ListItemIcon, ListItemText, IconButton, Menu, MenuItem, Input, Tooltip } from '@material-ui/core';
import BusinessIcon from '@mui/icons-material/Business';
import { Link } from 'react-router-dom';
import { constants } from 'consts';

const useStyles = makeStyles({
    icon: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
});

const QuickLinksSection = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [companies, setCompanies] = React.useState([]);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const BASEURL = constants.url.ums;
    useEffect(() => {
        const fetchCompanies = async () => {
            const token = window.localStorage.getItem('serviceToken');
            const url = `${BASEURL}/companies/by-user/`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const { data } = response;
            setCompanies(data);
        };
        fetchCompanies();
    }, []);
    return (
        <div>
            <Tooltip title="Companies">
                <IconButton
                    id="quick-button"
                    aria-controls="quick-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <BusinessIcon color="secondary" />
                </IconButton>
            </Tooltip>
            <Menu id="quick-menu" aria-labelledby="quick-button" anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <IconSearch fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        <Input type="text" placeholder="search" fullWidth />
                    </ListItemText>
                </MenuItem>
                {companies.map((item, index) => (
                    <Link
                        underline="none"
                        color="inherit"
                        target="_blank"
                        key={index}
                        to={`/dashboard/default/${item.company_id}`}
                        sx={{ '&:hover': { color: 'red' } }}
                    >
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <IconSwimming fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{item.company_name}</ListItemText>
                        </MenuItem>
                    </Link>
                ))}
            </Menu>
        </div>
    );
};

export default QuickLinksSection;
