import React from 'react';

// material-ui
import { Link, Typography, Stack } from '@material-ui/core';

// ===========================|| FOOTER - AUTHENTICATION 2 & 3 ||=========================== //

const AuthFooter = () => (
    <Stack direction="row" justifyContent="flex-end">
        {/* <Typography variant="subtitle2" component={Link} href="https://berrydashboard.io" target="_blank" underline="hover">
            berrydashboard.io
        </Typography> */}
        <Typography variant="subtitle2" component={Link} href="https://kredo.in" target="_blank" underline="hover">
            &copy; Kredo.in
        </Typography>
    </Stack>
);

export default AuthFooter;
