import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@material-ui/core';

// project imports
import config from 'config';
// import Logo from 'ui-component/Logo';
// import KredoLogo from 'ui-component/KredoLogo';
import Logo from 'assets/images/icons/logo.png';

// ===========================|| MAIN LOGO ||=========================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
        {/* <Logo /> */}
        <img src={Logo} style={{ width: '200px', height: '1.9rem' }} alt="Kredo Logo" />
        {/* <RouterLink to="#" style={{ textDecoration: 'none' }}> */}
        {/* <img style={{ width: '1.5rem' }} src={Logo} alt="Logo" /> */}
        {/* <span style={{ fontSize: '1.3rem', color: '#000' }}>Kredo&nbsp;</span>
        <span style={{ fontSize: '1.3rem', color: '#4232c2' }}>Analytics</span> */}
        {/* </RouterLink> */}
    </ButtonBase>
);

export default LogoSection;
