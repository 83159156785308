import React from 'react';

import PropTypes from 'prop-types';
import { TableCell, TableRow, Table, TableHead, TableBody } from '@mui/material';

const poBasicHeaders = [
    { title: 'Item' },
    { title: 'Rate/unit', align: 'right' },
    { title: 'Quantity', align: 'right' },
    { title: 'Tax', align: 'right' },
    { title: 'Price', align: 'right' }
];

const PoListItem = ({ poList, exchangeRate, isSales }) => {
    const handleSendPO = () => {
        console.log('add send invoice action');
    };
    return (
        <Table size="small" aria-label="purchases">
            <TableHead>
                <TableRow>
                    {poBasicHeaders
                        .filter((header) => {
                            if (isSales) {
                                if (header.title === 'Material') return false;
                                return true;
                            }
                            return true;
                        })
                        .map((header, index) => (
                            <TableCell key={index} scope="row" align={header.align ? header.align : 'inherit'}>
                                {header.title}
                            </TableCell>
                        ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {poList.map((PoItem, index) => (
                    <TableRow hover key={index}>
                        <TableCell>{PoItem.material_description}</TableCell>
                        <TableCell align="right">
                            {PoItem.rate_per_unit.toFixed(0).replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',')}
                        </TableCell>
                        <TableCell align="right">
                            {PoItem.quantity.toFixed(0).replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',')}
                        </TableCell>
                        <TableCell align="right">{PoItem.tax}</TableCell>
                        <TableCell align="right">
                            {((1 + PoItem.tax / 100) * (PoItem.rate_per_unit * PoItem.quantity) * exchangeRate)
                                .toFixed(0)
                                .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',')}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

PoListItem.propTypes = {
    poList: PropTypes.object,
    exchangeRate: PropTypes.number
};
export default PoListItem;
