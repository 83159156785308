import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

// routing

import Routes from './routes';

// defaultTheme
import themes from 'themes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
// import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import { getCompanyId } from 'utils/helper';

// auth provider
import { FirebaseProvider } from 'contexts/FirebaseContext';

// import Login from './components/screens/Login';
// import SignUp from './components/screens/SignUp';
import { JWTProvider } from 'contexts/JWTContext';
import { DatabaseNameContext } from 'views/poso/contexts/DatabaseNameContext';
import DashBoard from 'views/poso/views/DashBoard/DashBoard';
import { useParams } from 'react-router';
import { getPOSOSchema } from 'views/poso/views/DashBoard/NetworkCalls/UMS';
import config from 'config';
import { QueryClientProvider, QueryClient } from 'react-query';

// import {Auth0Provider} from 'contexts/Auth0Context';

// ===========================|| APP ||=========================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const [headerObject, setHeaders] = useState({});
    const companyId = getCompanyId(config.paths, config.paths.length);
    const queryClient = new QueryClient();
    useEffect(() => {
        const fetchHeader = async () => {
            if (companyId) {
                const headerObject = await getPOSOSchema(companyId);
                sessionStorage.setItem('schema', headerObject.schemaName.toLowerCase());
                sessionStorage.setItem('database', headerObject.database.toLowerCase());
                setHeaders({
                    'x-dts-database': headerObject.database.toLowerCase(),
                    'x-dts-schema': headerObject.schemaName.toLowerCase()
                });
            }
        };
        fetchHeader();
    }, [companyId]);

    return (
        <QueryClientProvider client={queryClient}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    {/* RTL layout */}
                    {/* <RTLLayout> */}
                    <Locales>
                        <NavigationScroll>
                            <DatabaseNameContext.Provider value={{ headerObject, updateHeaderObject: setHeaders }}>
                                <JWTProvider>
                                    {/* <SignUp /> */}
                                    {/* <Login /> */}
                                    <Routes />
                                    <Snackbar />
                                </JWTProvider>
                            </DatabaseNameContext.Provider>
                        </NavigationScroll>
                    </Locales>
                    {/* </RTLLayout> */}
                </ThemeProvider>
            </StyledEngineProvider>
        </QueryClientProvider>
    );
};

export default App;
