import React, { useState, useEffect } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Box } from '@mui/material';

import CustomTableDataGrid from '../DataTable';

import PropTypes from 'prop-types';

import { getPaymentsReceivedByInvoiceId } from '../NetworkCalls/Payments';

const columns = [
    { field: 'reference_number', headerName: 'Reference', width: 180 },
    { field: 'remarks', headerName: 'Remarks', width: 130 },
    { field: 'invoice_number', headerName: 'Invoice number', width: 160 },
    {
        field: 'date',
        headerName: 'Payment Date',
        width: 130,
        valueFormatter: (params) => new Date(params?.value).toLocaleDateString('en-IN', { day: '2-digit', month: 'short', year: 'numeric' })
    },
    {
        field: 'amount',
        headerName: 'Amount',
        width: 130,
        valueFormatter: (params) => params?.value.toFixed(0).replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ','),
        align: 'right'
    },
    { field: 'payment_status', headerName: 'Status', width: 130 },
    { field: 'customer_schema', headerName: 'Customer', width: 130, valueFormatter: (params) => params?.value.slice(0, -5) }
];

const PaymentsViewModal = (props) => {
    const { open, setOpen, invoiceId, totalAmount } = props;
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [paidAmount, setPaidAmount] = useState(0);

    const handleClose = () => {
        setOpen(false);
    };

    // useEffect(() => {
    //     (async () => {
    //         setIsLoading(true);
    //         const pending = await getPaymentsReceivedByInvoiceId(invoiceId);
    //         setRows(pending);
    //         setPaidAmount(pending.reduce((acc, cur) => acc + cur.amount, 0));
    //         setIsLoading(false);
    //     })();
    // }, [invoiceId]);

    return (
        <Box>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogContent>
                    <DialogContentText>Pending amount :{totalAmount - paidAmount}</DialogContentText>
                    <CustomTableDataGrid
                        rows={rows}
                        columns={columns}
                        isLoading={isLoading}
                        title="Payments Received"
                        identifier="payment_received_id"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

PaymentsViewModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    invoiceId: PropTypes.number,
    totalAmount: PropTypes.number
};
export default PaymentsViewModal;
