// Axios imports
import axios from 'axios';

// User imports
// Absolute imports
import { constants } from 'consts';

// Global variables
const RECO_URL = constants.url.reco;
const UMS_URL = constants.url.ums;

const getFollowUpLists = async (params) => {
    const url = `${RECO_URL}/reconciliation/followup_lists/`;
    try {
        const response = await axios.get(url, {
            params,
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return [];
};

const getRecoStatusCount = async (params, headers) => {
    const url = `${RECO_URL}/reconciliation/reco_status_count/`;
    try {
        const response = await axios.get(url, {
            params,
            headers
        });
        return response;
    } catch (err) {
        console.log(err);
        return [];
    }
};

const getProjectDetails = async (projectId) => {
    const url = `${RECO_URL}/reconciliation/projects/${projectId}/`;
    try {
        const response = await axios.get(url, {
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const getDownloadTemplate = async (companyId, projectId) => {
    const url = `${UMS_URL}/ums/task-assignment-template/`;
    try {
        const response = await axios.get(url, {
            params: {
                company_id: companyId,
                project_id: projectId
            },
            responseType: 'arraybuffer'
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const getReconcilers = async (companyId, toolId) => {
    const url = `${UMS_URL}/ums/reconcilers/`;
    try {
        const response = await axios.get(url, {
            params: {
                company_id: companyId,
                tool_id: toolId
            }
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return [];
};

const getReviewers = async (companyId, toolId) => {
    const url = `${UMS_URL}/ums/reviewers/`;
    try {
        const response = await axios.get(url, {
            params: {
                company_id: companyId,
                tool_id: toolId
            }
        });
        return response;
    } catch (err) {
        console.log(err);
        return [];
    }
};

const postBulkAssignment = async (formData, companyId, projectId) => {
    const url = `${UMS_URL}/ums/assign-recos/`;
    try {
        const response = await axios.post(url, formData, {
            params: {
                company_id: companyId,
                project_id: projectId
            }
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const postBulkMail = async (projectId, payload) => {
    const url = `${RECO_URL}/reconciliation/send_bulk_mail/`;
    try {
        const response = await axios.post(url, payload, {
            params: {
                project_id: projectId
            },
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const getReceivedFile = async (iterationId) => {
    const url = `${RECO_URL}/reconciliation/download_received_file/${iterationId}/`;
    try {
        const response = await axios.get(url, {
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const getLatestOutputFile = async (iterationId) => {
    const url = `${RECO_URL}/reconciliation/download_latest_output_file/${iterationId}/`;
    try {
        const response = await axios.get(url, {
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const postStartReco = async (params, payload) => {
    const url = `${RECO_URL}/reconciliation/state_machine/run_reco/`;
    try {
        const response = await axios.post(url, payload, {
            params,
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const getReconciliationDetails = async (params) => {
    const url = `${RECO_URL}/reconciliation/state_machine/start_reco/`;
    try {
        const response = await axios.get(url, {
            params,
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const getContinueReco = async (params) => {
    const url = `${RECO_URL}/reconciliation/state_machine/continue_reco/`;
    try {
        const response = await axios.get(url, {
            params,
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const postAcceptFile = async (params, payload) => {
    const url = `${RECO_URL}/reconciliation/state_machine/accept_file/`;
    try {
        const response = await axios.post(url, payload, {
            params,
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const postRejectFile = async (params, payload) => {
    const url = `${RECO_URL}/reconciliation/state_machine/reject_file/`;
    try {
        const response = await axios.post(url, payload, {
            params,
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const postRestartReco = async (params, payload) => {
    const url = `${RECO_URL}/reconciliation/state_machine/restart_reco/`;
    try {
        const response = await axios.post(url, payload, {
            params,
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const postCancelReco = async (params, payload) => {
    const url = `${RECO_URL}/reconciliation/state_machine/inactive_reco/`;
    try {
        const response = await axios.post(url, payload, {
            params,
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const postChangeStatus = async (path, params, payload) => {
    const url = `${RECO_URL}/reconciliation/${path}`;
    try {
        const response = await axios.post(url, payload, {
            params,
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const postChangeAssignee = async (params, payload) => {
    const url = `${RECO_URL}/reconciliation/state_machine/change_assignee/`;
    try {
        const response = await axios.post(url, payload, {
            params,
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const postChangeAssigner = async (params, payload) => {
    const url = `${RECO_URL}/reconciliation/state_machine/change_assigner/`;
    try {
        const response = await axios.post(url, payload, {
            params,
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const postCompleteReconciler = async (params, payload) => {
    const url = `${RECO_URL}/reconciliation/state_machine/complete_reconciler/`;
    try {
        const response = await axios.post(url, payload, {
            params,
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const getUploadDetails = async (iterationId) => {
    const url = `${RECO_URL}/reconciliation/is_sharing_possible/${iterationId}/`;
    try {
        const response = await axios.get(url, {
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const postShareFile = async (row, uploadId, companyId, userId) => {
    const url = `${RECO_URL}/reconciliation/share_reco/${row.reconcilee_co_id}/${uploadId}/`;

    const res1 = await axios.get(`${UMS_URL}/companies/${companyId}/`);
    const company = res1.data;
    const res2 = await axios.get(`${UMS_URL}/users/${userId}/`);
    const user = res2.data;
    try {
        const response = await axios.post(
            url,
            {
                shared_by_user_id: user.user_id,
                shared_by_user_name: user.user_name,
                shared_by_user_email: user.user_email,
                shared_by_company_id: companyId,
                shared_by_company_name: company.company_name,
                shared_reco_name: row.reco_name
            },
            {
                headers: {}
            }
        );
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const postMessage = async (params, payload) => {
    const url = `${RECO_URL}/reconciliation/state_machine/send_msg/`;
    try {
        const response = await axios.post(url, payload, {
            params,
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return [];
};

const postBulkMessage = async (params, payload) => {
    const url = `${RECO_URL}/reconciliation/send_bulk_mail/`;
    try {
        const response = await axios.post(url, payload, {
            params,
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return [];
};

const postRejectMessage = async (params, payload) => {
    const url = `${RECO_URL}/reconciliation/state_machine/send_mail/`;
    try {
        const response = await axios.post(url, payload, {
            params,
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return [];
};

const postGenerateLink = async (params) => {
    const url = `${RECO_URL}/reconciliation/state_machine/generate_link/`;
    try {
        const response = await axios.post(
            url,
            {},
            {
                params,
                headers: {}
            }
        );
        return response;
    } catch (err) {
        console.log(err);
    }
    return [];
};

const getReconcileeDetails = async (params) => {
    const url = `${UMS_URL}/companies/user_company/`;
    try {
        const response = await axios.get(url, {
            params
        });
        return response;
    } catch (err) {
        console.log(err);
        return {};
    }
};

const getCommunicationTemplates = async (params) => {
    const url = `${RECO_URL}/reconciliation/communication_templates/`;
    try {
        const response = await axios.get(url, {
            params,
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const postCommunicationTemplate = async (params, payload) => {
    const url = `${RECO_URL}/reconciliation/communication-rule/`;
    try {
        const response = await axios.post(url, payload, {
            params,
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const patchCommunicationTemplate = async (communicationRuleId, params, payload) => {
    const url = `${RECO_URL}/reconciliation/communication-rule/${communicationRuleId}/`;
    try {
        const response = await axios.patch(url, payload, {
            params,
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const getCommunicationAttributes = async (params) => {
    const url = `${RECO_URL}/reconciliation/company_communications/`;
    try {
        const response = await axios.get(url, {
            params,
            headers: {}
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const getCommunicationConfigs = async (params) => {
    const url = `${UMS_URL}/companies/company-communications-config/`;
    try {
        const response = await axios.get(url, { params });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const postCommunicationConfig = async (payload) => {
    const url = `${UMS_URL}/companies/company-communications-config/`;
    try {
        const response = await axios.post(url, payload);
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const patchCommunicationConfig = async (companyId, payload) => {
    const url = `${UMS_URL}/companies/company-communications-config/${companyId}/`;
    try {
        const response = await axios.patch(url, payload);
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const getTags = async (params, headers) => {
    const url = `${RECO_URL}/tags/`;
    try {
        const response = await axios.get(url, {
            params,
            headers
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const getTagMap = async (tagMapId, headers) => {
    const url = `${RECO_URL}/tags/map/${tagMapId}/`;
    try {
        const response = await axios.get(url, {
            headers
        });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const postTagMap = async (payload, headers) => {
    const url = `${RECO_URL}/tags/map/`;
    try {
        const response = await axios.post(url, payload, { headers });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

const putTagMap = async (tagMapId, payload, headers) => {
    const url = `${RECO_URL}/tags/map/${tagMapId}/`;
    try {
        const response = await axios.put(url, payload, { headers });
        return response;
    } catch (err) {
        console.log(err);
    }
    return {};
};

export {
    getFollowUpLists,
    getRecoStatusCount,
    getProjectDetails,
    getDownloadTemplate,
    postBulkAssignment,
    postBulkMail,
    getReceivedFile,
    postStartReco,
    getReconciliationDetails,
    getContinueReco,
    postAcceptFile,
    postRejectFile,
    getLatestOutputFile,
    getUploadDetails,
    postShareFile,
    postRestartReco,
    postCancelReco,
    postCompleteReconciler,
    postMessage,
    postBulkMessage,
    postGenerateLink,
    postRejectMessage,
    getReconcilers,
    getReviewers,
    postChangeStatus,
    postChangeAssignee,
    postChangeAssigner,
    getReconcileeDetails,
    getCommunicationTemplates,
    postCommunicationTemplate,
    patchCommunicationTemplate,
    getCommunicationAttributes,
    getCommunicationConfigs,
    postCommunicationConfig,
    patchCommunicationConfig,
    getTags,
    getTagMap,
    postTagMap,
    putTagMap
};
