import * as React from 'react';

import TextField from '@mui/material/TextField';

import { LocalizationProvider, DatePicker } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';

export default function PaymentDate({ date, setDate }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label="Enter/choose paymentDate"
                value={date}
                onChange={(newValue) => {
                    setDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} helperText={params?.inputProps?.placeholder} />}
            />
        </LocalizationProvider>
    );
}
