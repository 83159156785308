import { getWrapper, postWrapper, patchWrapper, deleteWrapper, getWrapperNew } from './Wrapper';
import { constants } from 'consts';

const {
    url: { ums: UMS_URL, poso: BASE_URL }
} = constants;

function getUrlParams(url, params) {
    params = Object.entries(params)
        .map((item) => item[0] + '=' + item[1])
        .join('&');
    return `${url}${params}`;
}

export const getPoReceived = async (params) => {
    const url = getUrlParams(`${BASE_URL}/po/received/?`, params);
    const response = await getWrapper(url, {});
    return response;
};

export const getPoReceivedById = async (params) => {
    params = Object.entries(params)
        .map((item) => item[0] + '=' + item[1])
        .join('&');
    const url = `${BASE_URL}/po/received/by/?${params}`;
    console.log(params);
    const response = await getWrapper(url, {});
    return response;
};

export const getPo = async (params) => {
    params = Object.entries(params)
        .map((item) => item[0] + '=' + item[1])
        .join('&');
    const url = `${BASE_URL}/po/?${params}`;
    const response = await getWrapper(url, {});
    return response;
};

export const getPoById = async (params) => {
    params = Object.entries(params)
        .map((item) => item[0] + '=' + item[1])
        .join('&');
    const url = `${BASE_URL}/po/by/?${params}`;
    console.log(url);
    const response = await getWrapper(url, {});
    return response;
};

export const acceptReceivedPo = async (headers, params, payload) => {
    params = Object.entries(params)
        .map((item) => item[0] + '=' + item[1])
        .join('&');
    const url = `${BASE_URL}/po/received/accept/?${params}`;
    const response = await patchWrapper(url, headers, payload);
    return response;
};

export const rejectReceivedPo = async (headers, params, payload) => {
    params = Object.entries(params)
        .map((item) => item[0] + '=' + item[1])
        .join('&');
    const url = `${BASE_URL}/po/received/reject/?${params}`;
    const response = await patchWrapper(url, headers, payload);
    return response;
};

export const getPOReceivedItems = async (poReceivedId, headers) => {
    const url = `${BASE_URL}/po/received/items/?po_received_id=${poReceivedId}`;
    const response = await getWrapper(url, headers);
    return response;
};

export const getPOItems = async (poId, headers) => {
    const url = `${BASE_URL}/po/items/?po_id=${poId}`;
    const response = await getWrapper(url, headers);
    return response;
};

export const patchPO = async (payload) => {
    const url = `${BASE_URL}/po/`;
    const response = await patchWrapper(url, {}, payload);
    return response;
};

export const postPO = async (payload) => {
    const url = `${BASE_URL}/po/`;
    const response = await postWrapper(url, {}, payload);
    return response;
};

export const getPOById = async (params) => {
    params = Object.entries(params)
        .map((item) => item[0] + '=' + item[1])
        .join('&');
    const url = `${BASE_URL}/po/by/?${params}`;

    const response = await getWrapper(url);
    return response;
};

export const postPOItem = async (payload, poId) => {
    const url = `${BASE_URL}/po/items/`;
    const response = await postWrapper(url, { po_id: poId }, payload);
    return response;
};

export const getExchangeRate = async (params) => {
    const url = `${BASE_URL}/rate/exchange?rate=${params.rate}&exchange=${params.exchange}&date=${params.date}`;
    const response = await getWrapper(url);
    return response;
};

export const fetchAttachmentsByPoId = async (poId) => {
    const url = `${BASE_URL}/po/attachments/po-id/${poId}`;
    const response = await getWrapper(url);
    return response;
};

export const deletePoListItemById = async (params) => {
    params = Object.entries(params)
        .map((item) => item[0] + '=' + item[1])
        .join('&');
    const url = `${BASE_URL}/po/items/?${params}`;
    await deleteWrapper(url);
};

export const postPOAttachments = async (poId, payload) => {
    const url = `${BASE_URL}/po/attachments/?po_id=${poId}`;
    const response = await postWrapper(url, {}, payload);
    return response;
};

export const sendPo = async (poId, userId, approvalInformationId, vendorSchema) => {
    const url = `${BASE_URL}/po/send/?po_id=${poId}&&user_id=${userId}&&approval_information_id=${approvalInformationId}`;
    const response = await postWrapper(url, {
        'vendor-schema': vendorSchema.toLowerCase(),
        'customer-schema': sessionStorage.getItem('schema')
    });
    return response;
};

export const getPoAttachmentById = async (poAttachmentID) => {
    const url = `${BASE_URL}/po/attachments/${poAttachmentID}/`;
    const response = await getWrapper(url);
    return response;
};

export const getPoAttachmentReceivedById = async (poAttachmentReceivedID) => {
    const url = `${BASE_URL}/po/attachments/received/${poAttachmentReceivedID}/`;
    const response = await getWrapper(url);
    return response;
};

export const getPOByStatus = async (poStatus) => {
    const url = `${BASE_URL}/po/by_status?po_status=${poStatus}`;
    const response = await getWrapper(url);
    return response;
};

export const getPOReceivedByStatus = async (poStatus) => {
    const url = `${BASE_URL}/po/received_by_status?po_status=${poStatus}`;
    const response = await getWrapper(url);
    return response;
};

export const updatePO = async (payload) => {
    const url = `${BASE_URL}/po/update/${payload.po_id}/`;
    const response = await patchWrapper(url, {}, payload);
    return response;
};

export const updatePOItems = async (poItemId, payload) => {
    const url = `${BASE_URL}/po/update/items/${poItemId}`;
    const response = await patchWrapper(url, {}, payload);
    return response;
};

export const getPoStatusById = (params) => {
    const url = getUrlParams(`${BASE_URL}/po/status/?`, params);
    return getWrapper(url);
};

export const getPoListValidation = () => {
    const url = `${BASE_URL}/po/validation/`;
    return getWrapper(url);
};
