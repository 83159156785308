import { useQuery } from 'react-query';

import { getPoStatusById, getPOById, getPo, getPoReceived, getPoReceivedById } from '../PO';

export const UseGetPoTrailById = (poId) =>
    useQuery(['POtrail', poId], () => getPoStatusById({ po_id: poId }), {
        refetchOnWindowFocus: false,
        enabled: !!poId
    });

export const UseGetPoDetails = (poId) =>
    useQuery(['POdetails', poId], () => getPOById({ po_id: poId }), {
        refetchOnWindowFocus: false,
        enabled: !!poId
    });

export const UseGetPos = (params) =>
    useQuery('paginatedPos', () => getPo(params), {
        refetchOnWindowFocus: false,
        enabled: !!params
    });
export const UseGetReceivedPos = (params) =>
    useQuery('paginatedPosReceived', () => getPoReceived(params), {
        refetchOnWindowFocus: false,
        enabled: !!params
    });

export const UseGetPOReceivedById = (params) =>
    useQuery(['POById', params], () => getPoReceivedById(params), { refetchOnWindowFocus: false, enabled: !!params.po_received_id });
