import React, { useState, useEffect } from 'react';

import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box } from '@mui/material';

import PropTypes from 'prop-types';

import PaymentDate from '../Components/DateTimePicker';
import { getPaymentsByInvoiceReceivedId } from '../NetworkCalls/Payments';

const PaymentModal = (props) => {
    const { open, setOpen, handlePayment, pendingAmount } = props;
    const [remarks, setRemarks] = useState('');
    const [reference, setReference] = useState('');
    const [date, setDate] = useState(new Date());
    const [amount, setAmount] = useState(pendingAmount);
    const [paymentError, setPaymentError] = useState(false);
    const handleConfirm = async () => {
        await handlePayment({ remarks, date, amount, reference });
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Payment</DialogTitle>
                <DialogContent>
                    <DialogContentText>Enter Payment Reference Number</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="ref-number"
                        label="Reference number"
                        placeholder="PAY/REC/1001"
                        fullWidth
                        value={reference}
                        variant="standard"
                        onChange={(e) => {
                            setReference(e.target.value);
                        }}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="remarks"
                        label="Remarks"
                        placeholder="Write your remarks"
                        fullWidth
                        value={remarks}
                        variant="standard"
                        onChange={(e) => {
                            setRemarks(e.target.value);
                        }}
                    />
                    <DialogContentText>Enter Payment Amount</DialogContentText>
                    <TextField
                        autoFocus
                        error={paymentError}
                        margin="dense"
                        type="number"
                        id="payment-amount"
                        label="Amount"
                        placeholder="10000"
                        fullWidth
                        variant="standard"
                        value={amount}
                        onChange={(e) => {
                            if (e.target.value > pendingAmount) {
                                setPaymentError(true);
                            } else {
                                setPaymentError(false);
                            }
                            setAmount(e.target.value);
                        }}
                        helperText={paymentError ? 'Incorrect entry' : ''}
                    />

                    <DialogContentText>
                        Pending amount :{pendingAmount?.toFixed(2).replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',')}
                    </DialogContentText>
                    <br />
                    <br />
                    <PaymentDate date={date} setDate={setDate} />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm}>Confirm payment</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

PaymentModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handlePayment: PropTypes.func,
    pendingAmount: PropTypes.number
};
export default PaymentModal;
