import React, { lazy } from 'react';

// project imports
// import MainLayout from 'layout/MainLayout';
import CustomLayout from 'layout/CustomLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import InvoiceRaised from 'views/poso/views/DashBoard/Invoice/InvoiceRaised';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardAnalytics = Loadable(lazy(() => import('views/dashboard/Analytics')));

// application - user social & account profile routing
const AppUserAccountProfile1 = Loadable(lazy(() => import('views/application/users/account-profile/Profile1')));

const Login = Loadable(lazy(() => import('../components/screens/Login')));
const SignUp = Loadable(lazy(() => import('../components/screens/SignUp')));
const CompanyDashboard = Loadable(lazy(() => import('views/dashboard/CompanyDashboard')));
const CompanyDetails = Loadable(lazy(() => import('views/dashboard/CompanyDashboard/CompanyDetails')));
const InviteUser = Loadable(lazy(() => import('views/dashboard/CompanyDashboard/UserInvite')));
const InviteUserForm = Loadable(lazy(() => import('views/dashboard/CompanyDashboard/InviteUserForm')));
const VendorCustomerFull = Loadable(lazy(() => import('views/reconciliation/VendorCustomer/VendorList')));
const SharedVendorRecos = Loadable(lazy(() => import('views/reconciliation/VendorCustomer/SharedVendorRecos')));
const LatestGstRecos = Loadable(lazy(() => import('views/reconciliation/VendorCustomer/LatestGstRecos')));
const Programs = Loadable(lazy(() => import('views/reconciliation/Config/Programs')));
const Rules = Loadable(lazy(() => import('views/reconciliation/Config/Rules')));
const Profile = Loadable(lazy(() => import('views/dashboard/CompanyDashboard/Profile')));
const EditInviteeProfile = Loadable(lazy(() => import('views/dashboard/CompanyDashboard/EditInviteeProfile')));
const CompanyProfile = Loadable(lazy(() => import('views/application/users/account-profile/CompanyProfile')));
const AppKanban = Loadable(lazy(() => import('views/application/kanban')));
const InviteCompany = Loadable(lazy(() => import('views/dashboard/CompanyDashboard/InviteCompany')));
const DashboardSummary = Loadable(lazy(() => import('views/reconciliation/VendorCustomer/components/DashboardSummary')));
const DashboardSummaryDetail = Loadable(lazy(() => import('views/reconciliation/VendorCustomer/components/DashboardSummaryDetail')));
const GstDashboardSummary = Loadable(lazy(() => import('views/reconciliation/GstReco/Summary/index')));
const GstSummaryDetail = Loadable(lazy(() => import('views/reconciliation/GstReco/Summary/GstSummaryDetail')));
const ProjectLists = Loadable(lazy(() => import('views/reconciliation/VendorCustomer/Projects/ProjectLists')));
const ProjectHome = Loadable(lazy(() => import('views/reconciliation/VendorCustomer/Projects/ProjectHome')));
const RecoHistory = Loadable(lazy(() => import('views/reconciliation/VendorCustomer/Projects/RecoHistory')));
const CreateTags = Loadable(lazy(() => import('views/reconciliation/VendorCustomer/components/CreateTags')));
const RecoPeriodEdit = Loadable(lazy(() => import('views/reconciliation/VendorCustomer/components/RecoPeriodEdit')));
const CommunicationTemplate = Loadable(lazy(() => import('views/reconciliation/VendorCustomer/Projects/CommunicationTemplate')));
const VendorCustomerDetails = Loadable(lazy(() => import('views/dashboard/CompanyDashboard/VendorCustomerCompanyDetails')));

// POSO //
const POSO = Loadable(lazy(() => import('views/poso/index')));
// PO routing
const ListPo = Loadable(lazy(() => import('views/poso/views/DashBoard/PO/ListPo')));
const ApprovePo = Loadable(lazy(() => import('views/poso/views/DashBoard/PO/ApprovePo')));
const ViewApprovePo = Loadable(lazy(() => import('views/poso/views/DashBoard/PO/ViewApprovePo')));

// payments po routing
const Payable = Loadable(lazy(() => import('views/poso/views/DashBoard/Payments/Payable')));
const PaymentReceived = Loadable(lazy(() => import('views/poso/views/DashBoard/Payments/PaymentsReceived')));
const AdvancePayments = Loadable(lazy(() => import('views/poso/views/DashBoard/Payments/AdvancePayments')));

// reports page routing
const Report1 = Loadable(lazy(() => import('views/poso/views/DashBoard/Reports/Report1')));
const Report2 = Loadable(lazy(() => import('views/poso/views/DashBoard/Reports/Report2')));

// Chart Of Accounts
const ChartOfAccounts = Loadable(lazy(() => import('views/poso/views/DashBoard/ChartOfAccounts/ChartOfAccounts')));

//  Add Po page routing
const AddPo = Loadable(lazy(() => import('views/poso/views/DashBoard/Add_Edit/AddPo')));

const DashBoardPanel = Loadable(lazy(() => import('views/poso/views/DashBoard/DashBoardPanel')));

const ViewReceivedPO = Loadable(lazy(() => import('views/poso/views/DashBoard/PO/ViewReceivedPO')));
// Invoice Routes

const ViewInvoiceReceived = Loadable(lazy(() => import('views/poso/views/DashBoard/Invoice/ViewInvoiceReceived')));
const ApprovalPendingInvoices = Loadable(lazy(() => import('views/poso/views/DashBoard/Invoice/ApprovalPendingInvoice')));

// timeline routing
const PushTimeLine = Loadable(lazy(() => import('views/poso/views/DashBoard/Timeline')));
const Settings = Loadable(lazy(() => import('views/poso/views/DashBoard/Settings')));
const InvoiceList = Loadable(lazy(() => import('views/poso/views/DashBoard/Invoice/ListInvoice')));
const ApproveInvoice = Loadable(lazy(() => import('views/poso/views/DashBoard/Invoice/ApproveInvoice')));
const PoReceived = Loadable(lazy(() => import('views/poso/views/DashBoard/PO/PoReceived')));
const InvoiceReceived = Loadable(lazy(() => import('views/poso/views/DashBoard/Invoice/InvoiceReceived')));

// ===================================================// POSO //======================================================== //
// Purchases
const PurchaseOrder = Loadable(lazy(() => import('views/poso/views/Purchases/POList')));
const PurchaseInvoice = Loadable(lazy(() => import('views/poso/views/Purchases/PurchaseInvoiceList')));
const POAdvance = Loadable(lazy(() => import('views/poso/views/Purchases/Advance')));
const AddPOForm = Loadable(lazy(() => import('views/poso/views/Purchases/AddEdit/AddPO')));
const ViewPO = Loadable(lazy(() => import('views/poso/views/Purchases/ViewPO')));
const EditPO = Loadable(lazy(() => import('views/poso/views/Purchases/AddEdit/EditPO')));
const ViewPurchaseInvoice = Loadable(lazy(() => import('views/poso/views/Purchases/PurchaseInvoice')));
const ViewInvoiceApproval = Loadable(lazy(() => import('views/poso/views/Purchases/ApprovalInvoice/InvoiceApproval')));
// Sales
const SalesPO = Loadable(lazy(() => import('views/poso/views/Sales/POList')));
const Invoice = Loadable(lazy(() => import('views/poso/views/Sales/Invoices')));
const Collections = Loadable(lazy(() => import('views/poso/views/Sales/Collections')));
const AddInvoice = Loadable(lazy(() => import('views/poso/views/Sales/Invoice/CreateInvoice')));
const EditInvoice = Loadable(lazy(() => import('views/poso/views/Sales/Invoice/EditInvoice')));
const ViewInvoice = Loadable(lazy(() => import('views/poso/views/Sales/Invoice/ViewInvoice')));
// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <CustomLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/default/create-profile/:companyId',
            element: <CompanyProfile />
        },
        {
            path: '/dashboard/default/invite-users/:companyId',
            element: <InviteUser />
        },
        {
            path: '/dashboard/default/invite-company/:companyId',
            element: <InviteCompany />
        },
        {
            path: '/dashboard/default/invite-user-form/:companyId',
            element: <InviteUserForm />
        },
        {
            path: '/dashboard/default/:companyId',
            element: <CompanyDashboard />
        },
        {
            path: '/dashboard/default/customer-vendor-details/:companyId',
            element: <VendorCustomerDetails />
        },
        {
            path: '/dashboard/default/:companyId/reconciliation/vendor-customer/full-version-1/home/',
            element: <VendorCustomerFull />
        },
        {
            path: '/dashboard/default/:companyId/reconciliation/vendor-customer/full-version-1/vendor/latest-recos/:recoType/',
            element: <ProjectHome />
        },
        {
            path: '/dashboard/default/:companyId/reconciliation/vendor-customer/full-version-1/vendor/shared-recos/',
            element: <SharedVendorRecos />
        },
        {
            path: '/dashboard/default/:companyId/reconciliation/vendor-customer/full-version-1/vendor/projects/',
            element: <ProjectLists />
        },
        {
            path: '/dashboard/default/:companyId/reconciliation/vendor-customer/full-version-1/vendor/projects/:projectId',
            element: <ProjectHome />
        },
        {
            path: '/dashboard/default/:companyId/reconciliation/vendor-customer/full-version-1/gst/gst-latest-recos/',
            element: <LatestGstRecos />
        },
        {
            path: '/dashboard/default/:companyId/reconciliation/vendor-customer/history/:recoId/',
            element: <RecoHistory />
        },
        {
            path: '/dashboard/default/:companyId/reconciliation/vendor-customer/tags/:recoId/:tagId/',
            element: <CreateTags />
        },
        {
            path: '/dashboard/default/:companyId/reconciliation/vendor-customer/period/:recoIterationId/',
            element: <RecoPeriodEdit />
        },
        {
            path: '/dashboard/default/:companyId/reconciliation/programs',
            element: <Programs />
        },
        {
            path: '/dashboard/default/:companyId/reconciliation/rules',
            element: <Rules />
        },
        {
            path: '/profile/:userId',
            element: <AppUserAccountProfile1 />
        },
        {
            path: '/edit-profile/:inviteId',
            element: <EditInviteeProfile />
        },
        {
            path: '/dashboard/default/:companyId/reconciliation/vendor-customer/full-version-1/:recoType/latest-recos/summary/:recoIterationId/:getFrom',
            element: <DashboardSummary />
        },
        {
            path: '/dashboard/default/:companyId/reconciliation/vendor-customer/full-version-1/:recoType/latest-recos/summary/:id/:getFrom/:status/:category/:remark/:remark2',
            element: <DashboardSummaryDetail />
        },
        {
            path: '/dashboard/default/:companyId/reconciliation/vendor-customer/full-version-1/:recoType/latest-recos/summary/:recoIterationId/:getFrom',
            element: <GstDashboardSummary />
        },
        {
            path: '/dashboard/default/:companyId/poso/*',
            element: null
        },
        {
            path: '/dashboard/default/:companyId/poso/DashBoard',
            element: <DashBoardPanel />
        },
        {
            path: '/dashboard/default/:companyId/poso/po/list',
            element: <ListPo />
        },
        {
            path: '/dashboard/default/:companyId/poso/po/approve',
            element: <ApprovePo />
        },
        {
            path: '/dashboard/default/:companyId/poso/po/received',
            element: <PoReceived />
        },
        // poso components
        // Purchases
        {
            path: '/dashboard/default/:companyId/poso/po/Add',
            element: <AddPOForm />
        },
        {
            path: '/dashboard/default/:companyId/poso/edit-po/:poId',
            element: <EditPO />
        },
        {
            path: '/dashboard/default/:companyId/poso/po',
            element: <PurchaseOrder />
        },
        {
            path: '/dashboard/default/:companyId/poso/purchase/po/invoice',
            element: <PurchaseInvoice />
        },
        {
            path: '/dashboard/default/:companyId/poso/view-purchase-invoice/:purchaseInvoiceId',
            element: <ViewPurchaseInvoice />
        },
        {
            path: '/dashboard/default/:companyId/poso/purchase/po/advance',
            element: <POAdvance />
        },
        // Sales
        {
            path: '/dashboard/default/:companyId/poso/sales',
            element: <SalesPO />
        },
        {
            path: '/dashboard/default/:companyId/poso/sales/invoice',
            element: <Invoice />
        },
        {
            path: '/dashboard/default/:companyId/poso/sales/collection',
            element: <Collections />
        },
        ///   End
        {
            path: '/dashboard/default/:companyId/poso/invoice/received',
            element: <InvoiceReceived />
        },
        {
            path: '/dashboard/default/:companyId/poso/invoice/approve',
            element: <ApprovalPendingInvoices />
        },
        {
            path: '/dashboard/default/:companyId/poso/tables/payable',
            element: <Payable />
        },
        {
            path: '/dashboard/default/:companyId/poso/payments/received',
            element: <PaymentReceived />
        },
        {
            path: '/dashboard/default/:companyId/poso/payments/advance',
            element: <AdvancePayments />
        },
        {
            path: '/dashboard/default/:companyId/poso/ChatOfAccounts',
            element: <ChartOfAccounts />
        },
        {
            path: '/dashboard/default/:companyId/poso/reports/report-1',
            element: <Report1 />
        },
        {
            path: '/dashboard/default/:companyId/poso/reports/report-2',
            element: <Report2 />
        },
        {
            path: '/dashboard/default/:companyId/poso/AddPo',
            element: <AddPo />
        },
        {
            path: '/dashboard/default/:companyId/poso/AddInvoice/:salesPOId',
            element: <AddInvoice />
        },
        {
            path: '/dashboard/default/:companyId/poso/EditInvoice/:salesPOId/:invoiceId',
            element: <EditInvoice />
        },
        {
            path: '/dashboard/default/:companyId/poso/view-po/:poId',
            element: <ViewPO />
        },
        {
            path: '/dashboard/default/:companyId/poso/view-received-po/:poReceivedId',
            element: <ViewReceivedPO />
        },
        {
            path: '/dashboard/default/:companyId/poso/view-invoice/:invoiceId',
            element: <ViewInvoice />
        },
        {
            path: '/dashboard/default/:companyId/poso/view-received-invoice/:invoiceReceivedId',
            element: <ViewInvoiceReceived />
        },
        {
            path: '/dashboard/default/:companyId/poso/view-invoice-approval/:invoiceReceivedId',
            element: <ViewInvoiceApproval />
        },
        {
            path: '/dashboard/default/:companyId/poso/DashBoardPo/TimeLine',
            element: <PushTimeLine />
        },
        {
            path: '/dashboard/default/:companyId/poso/approve-po/:id',
            element: <ViewApprovePo />
        },
        {
            path: '/dashboard/default/:companyId/poso/settings',
            element: <Settings />
        },
        {
            path: '/dashboard/default/:companyId/reconciliation/vendor-customer/full-version-1/gst-summary/:id/:getFrom/:status/:category/:remark/:remark2',
            element: <GstSummaryDetail />
        },
        {
            path: '/dashboard/default/:companyId/reconciliation/vendor-customer/full-version-1/communication-template',
            element: <CommunicationTemplate />
        }
    ]
};

export default MainRoutes;
