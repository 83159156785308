import React, { useEffect } from 'react';
import axios from 'axios';

import { IconGridDots, IconSwimming, IconPlus } from '@tabler/icons';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Button, ListItemIcon, ListItemText, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { constants } from 'consts';
import useAuth from 'hooks/useAuth';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import useCompanyIdFunc from 'hooks/useCompanyIdFunc';

const useStyles = makeStyles({
    icon: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
});

const CompanySection = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user: userId } = useAuth();

    const companyId = useCompanyIdFunc();
    const classes = useStyles();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const [tools, setTools] = React.useState([]);
    const BASEURL = constants.url.ums;

    useEffect(() => {
        const fetchTools = async () => {
            const token = window.localStorage.getItem('serviceToken');
            const url = `${BASEURL}/companies/company-tool-subscription-details/`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    company_id: companyId
                }
            });
            const { data } = response;

            setTools(data);
        };
        fetchTools();
    }, []);

    const addTool = async (companyId, toolId, userId) => {
        const url = `${BASEURL}/tools/subscription-requests/`;

        const token = window.localStorage.getItem('serviceToken');

        const payload = {
            subscription_company_id: companyId,
            subscription_tool_id: toolId,
            subscription_created_by_user_id: userId
        };

        const response = await axios.post(url, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const navigateToReco = async (event) => {
        setAnchorEl(null);
        const token = window.localStorage.getItem('serviceToken');
        const url = `${BASEURL}/companies/database-details/?company_id=${companyId}&tool_prefix=reco`;
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const { data } = response;
        navigate(`/dashboard/default/${companyId}/reconciliation/vendor-customer/full-version-1/home/?userId=${userId}`);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const Errorhandle = () => {
        // add snackbar use dispatch
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Tool is not subscribed',
            variant: 'alert',
            alertSeverity: 'success',
            close: true
        });
    };

    return (
        <>
            <Tooltip title="Apps">
                <IconButton
                    id="tools-button"
                    aria-controls="tools-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <IconGridDots color={theme.palette.secondary.main} />
                </IconButton>
            </Tooltip>
            <Menu id="tools-menu" aria-labelledby="tools-button" anchorEl={anchorEl} open={open} onClose={handleClose}>
                {tools.map(
                    (item, index) =>
                        item.is_licensable && (
                            <MenuItem key={index}>
                                <ListItemIcon onClick={item.hasAccess ? navigateToReco : Errorhandle} disabled={item.hasAccess}>
                                    <IconSwimming fontSize="small" />
                                </ListItemIcon>
                                <ListItemText onClick={item.hasAccess ? navigateToReco : Errorhandle} disabled={item.hasAccess}>
                                    {item.tool_name}
                                </ListItemText>
                                {companyId ? (
                                    <IconButton onClick={() => addTool(companyId, item.tool_id, userId)}>
                                        {item.is_licensable && (item.isSubscribed ? null : <IconPlus fontSize="small" />)}
                                    </IconButton>
                                ) : null}
                            </MenuItem>
                        )
                )}
            </Menu>
        </>
    );
};

export default CompanySection;
