// Axios imports
import axios from 'axios';

// User imports
// Absolute imports
import { constants } from 'consts';

// Global variables
const RECO_URL = constants.url.reco;

const getReconciliationDetails = async (params) => {
    const url = `${RECO_URL}/reconciliation/reconciliation_details/`;
    try {
        const response = await axios.get(url, { params });
        return response;
    } catch (err) {
        console.log(err);
        return err.response;
    }
};

export { getReconciliationDetails };
