export default {
    url: {
        reco: 'http://localhost:8080/api',
        ums: 'http://localhost:8000',
        ui: 'http://localhost:3000',
        gstReco: 'http://localhost:8081/gst',
        gstApi: 'http://localhost:8081',
        poso: 'http://localhost:8082'
    }
};
