import PropTypes from 'prop-types';
import React, { createContext, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import axios from 'axios';
import Loader from 'ui-component/Loader';
import { constants } from 'consts';

import config from 'config';
import { getCompanyId } from 'utils/helper';

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    companyId: undefined,
    userPermissions: []
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    return decoded.exp > Date.now() / 1000;
};

const getScopes = (serviceToken) => {
    if (!serviceToken) {
        return [];
    }
    const decoded = jwtDecode(serviceToken);
    return decoded.scopes;
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ===========================|| JWT CONTEXT & PROVIDER ||=========================== //

const JWTContext = createContext({
    ...initialState,
    login: () => Promise.resolve(),
    logout: () => {},
    set_company_resources: () => Promise.resolve()
});

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const companyId = useSelector((state) => state.account.companyId);

    const login = async (email, password) => {
        const formData = new FormData();
        formData.append('username', email);
        formData.append('password', password);
        const response = await axios.post(`${constants.url.ums}/users/token/`, formData);

        // eslint-disable-next-line camelcase
        const { access_token, user } = response.data;

        const resp = await axios.get(`${constants.url.ums}/users/${user}/`, {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        });
        const data = resp.data.user_status;
        if (data) {
            const userPermissions = getScopes(access_token);
            setSession(access_token);
            dispatch({
                type: LOGIN,
                payload: {
                    user,
                    userPermissions,
                    companyId: undefined
                }
            });
        }
        return { data, access_token, user, companyId };
    };

    const set_company_resources = async (companyId) => {
        const token = window.localStorage.getItem('serviceToken');
        const url = `${constants.url.ums}/users/current-user-to-company-resources/`;
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                company_id: companyId
            }
        });
        const { access_token, user } = response.data;
        setSession(access_token);

        const userPermissions = getScopes(access_token);
        dispatch({
            type: ACCOUNT_INITIALIZE,
            payload: {
                isLoggedIn: true,
                user,
                companyId,
                userPermissions
            }
        });
        return response.data;
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    const response = await axios.get(`${constants.url.ums}/users/me/`);
                    const user = response.data.user_id;
                    const userPermissions = getScopes(serviceToken);
                    if (companyId) {
                        const decoded = jwtDecode(serviceToken);
                        set_company_resources(companyId);
                    }

                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: true,
                            user,
                            companyId,
                            userPermissions
                        }
                    });
                } else {
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: false,
                            user: null,
                            companyId: null,
                            userPermissions: []
                        }
                    });
                }
            } catch (err) {
                console.log(err);
                dispatch({
                    type: ACCOUNT_INITIALIZE,
                    payload: {
                        isLoggedIn: false,
                        user: null,
                        companyId: null,
                        userPermissions: []
                    }
                });
            }
        };

        init();
    }, [companyId]);

    if (!state.isInitialized) {
        return <Loader />;
    }

    return <JWTContext.Provider value={{ ...state, login, logout, set_company_resources }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default JWTContext;
