import { getWrapper } from './Wrapper';

import { constants } from 'consts';

const {
    url: { ums: UMS_URL, poso: BASE_URL }
} = constants;

const headers = {
    Authorization: `Bearer ${localStorage.getItem('serviceToken')}`
};

export const getPOSOSchema = async (companyId) => {
    const url = `${UMS_URL}/companies/poso-schema-data/${companyId}/`;
    const data = await getWrapper(url, {
        Authorization: `Bearer ${localStorage.getItem('serviceToken')}`
    });
    return { schemaName: data.db_schema_prefix + '.' + data.db_tool_prefix, database: data.database };
};

export const getCurrentUser = async () => {
    const url = `${UMS_URL}/users/me/`;
    const data = await getWrapper(url, headers);
    return data;
};

export const getVendors = async (companyId) => {
    const url = `${UMS_URL}/companies/company-connections/?company_id=${companyId}`;
    const data = await getWrapper(url, headers);
    return data;
};

export const getVendorSegmentUsers = async (companyId, segmentId) => {
    const url = `${UMS_URL}/companies/get_segment_responsible_persons?company_id=${companyId}&segment_id=${segmentId}`;
    const data = await getWrapper(url, headers);
    return data.users_list;
};

export const getVendorCompanyUsers = async (companyId) => {
    const url = `${UMS_URL}/users/by-company/?company_id=${companyId}`;
    const data = await getWrapper(url, headers);
    return data;
};

export const getPlantsAndDepartments = async (companyId, segmentId) => {
    const url = `${UMS_URL}/companies/get-organization-segment-by-type-id/${companyId}${segmentId}`;
    const data = await getWrapper(url, headers);
    return data;
};

export const getCompanyProfile = async (companyId) => {
    const url = `${UMS_URL}/companies/profiles/?company_id=${companyId}`;
    const data = await getWrapper(url, headers);
    return data;
};
export const getPlantGST = async (companyId) => {
    const url = `${UMS_URL}/companies/get-organization-segment-gst/${companyId}1`;
    const data = await getWrapper(url, headers);
    return data;
};

export const getCurrencies = async () => {
    const url = `${UMS_URL}/static/get_currencies`;
    const data = await getWrapper(url);
    console.log(data, 'currency');
    return data.currencies_list;
};

export const getCompanyBaseCurrency = async (companyId) => {
    const url = `${UMS_URL}/companies/get-base-currency/${companyId}`;
    const data = await getWrapper(url);
    return data.company_country;
};
