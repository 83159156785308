import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Avatar, Box, ButtonBase, Button, Link, Tooltip, IconButton } from '@material-ui/core';

// project imports
import CompanySection from './CompanySection';
import QuickLinksSection from './QuickLinksSection';
import ProfileSection from './ProfileSection';
import ToolsSection from './ToolsSection';
import LogoSection from './LogoSection';
import { IconChevronLeft, IconChevronRight, IconHome, IconMenu2 } from '@tabler/icons';
import { useSelector } from 'react-redux';
import { constants } from 'consts';

import useCompanyIdFunc from 'hooks/useCompanyIdFunc';

const BASEURL = constants.url.ums;

// assets
// import {  } from '@tabler/icons';

// style constant
const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    headerAvatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        transition: 'all .2s ease-in-out',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
        }
    },
    boxContainer: {
        width: '200px',
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            width: 'auto'
        }
    },
    heading: {
        color: 'purple',
        textTransform: 'capitalize'
    }
}));

// ===========================|| MAIN NAVBAR / HEADER ||=========================== //

const Header = ({ handleLeftDrawerToggle, leftDrawerOpened }) => {
    const classes = useStyles();
    const appIcon = useSelector((state) => state.apps);
    const companyId = useCompanyIdFunc();
    const [companyName, setCompanyName] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const token = window.localStorage.getItem('serviceToken');
            const url = `${BASEURL}/companies/${companyId}/`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setCompanyName(response.data.company_name);
        }

        if (companyId) {
            fetchData();
        }
    }, [companyId]);

    return (
        <>
            {/* logo & toggler button */}
            {handleLeftDrawerToggle && (
                <Button onClick={handleLeftDrawerToggle} variant="standard" type="button">
                    {leftDrawerOpened ? <IconChevronLeft color="purple" /> : <IconChevronRight color="purple" />}
                </Button>
            )}
            <div className={classes.grow}>
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    {companyId ? <h3 className={classes.heading}>{companyName}</h3> : <LogoSection />}
                </Box>
            </div>
            {/* header search */}
            <div className={classes.grow} />
            <div className={classes.grow} />
            {/* live customization & localization */}
            {/* notification & profile */}
            {/* <NotificationSection /> */}
            {/* <QuickLinksSection /> */}{' '}
            <Tooltip title="Home">
                <Button disableRipple href="/dashboard/default" component={Link} sx={{ mx: -1 }} color="secondary">
                    <IconHome />
                </Button>
            </Tooltip>
            <CompanySection />
            {companyId && <ToolsSection />}
            <ProfileSection />
            {/* mobile header */}
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
