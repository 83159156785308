import dashboard from './dashboard';
import widget from './widget';
import application from './application';
import forms from './forms';
import elements from './elements';
import pages from './pages';
import utilities from './utilities';
import support from './support';
import other from './other';
import Admin from './Admin';

// ===========================|| MENU ITEMS ||=========================== //

const menuItems = {
    items: [Admin]
};

export default menuItems;
