import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { SNACKBAR_OPEN } from 'store/actions';
import {
    Collapse,
    TableCell,
    TableRow,
    IconButton,
    Table,
    TableHead,
    TableBody,
    Tooltip,
    Button,
    ButtonGroup,
    TableContainer
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PaymentIcon from '@mui/icons-material/Payment';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { getInvoiceItems, getInvoiceReceivedItems, rejectInvoice, acceptInvoice, confirmInvoice } from '../NetworkCalls/Invoice';
import { IconSend, IconEdit } from '@tabler/icons';
import PaymentModal from './Modal';
import PaymentsViewModal from './PaymentViewModel';
import { createPayment } from '../NetworkCalls/Payments';

import { getCurrentUser } from '../NetworkCalls/UMS';
import RejectModal from '../RejectModel';

const invoiceBasicHeaders = [
    { title: 'Date' },
    { title: 'Invoice number' },
    { title: 'Invoice amount', align: 'right' },
    { title: 'Status' },
    { title: 'Actions' }
];
const InvoiceItemsBasicHeaders = [
    { title: 'Description' },
    { title: 'Rate/unit', align: 'right' },
    { title: 'Quantity', align: 'right' },
    { title: 'Tax', align: 'right' },
    { title: 'Amount', align: 'right' }
];

const InvoiceRow = ({ invoice: invoiceProp, poId, isSales, exchangeRate, companyId, userId }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [invoice, setInvoice] = useState(invoiceProp);
    const [modalOpen, setModalOpen] = useState(false);
    const [openReject, setRejectOpen] = useState(false);

    const handleSendInvoice = () => {
        console.log('add send invoice action');
    };

    const fetchPurchaseItems = async (invoiceId) => {
        setInvoiceItems(JSON.parse(invoiceProp.invoice_items));
    };
    const fetchSalesItems = async (invoiceId) => {
        setInvoiceItems(JSON.parse(invoiceProp.invoice_items));
    };

    const handleOpen = async (invoiceId) => {
        if (!open) {
            if (isSales) {
                await fetchSalesItems(invoiceId);
            } else {
                await fetchPurchaseItems(invoiceId);
            }
        }
        setOpen(!open);
    };

    const handleClaim = async ({ reference, amount, remarks, date }) => {
        const params = {
            invoice_id: invoice.invoice_id,
            invoice_received_id: invoice.invoice_received_id
        };
        const headers = {
            'customer-schema': invoice.customer_schema,
            'vendor-schema': invoice.vendor_schema
        };
        // const payload = {
        //     payment_reference_number: remarks
        // };
        const payloadCreate = {
            invoice_received_id: invoice.invoice_id,
            reference_number: reference,
            date: new Date(date).toISOString().slice(0, 10),
            amount,
            remarks,
            user_id: userId
        };
        await createPayment(payloadCreate, headers);
        // await claimReceivedInvoice(payload, params, headers);
        setInvoice({ ...invoice, invoice_status_master_id: 10, invoice_status: 'Payment Claimed' });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Payment Successful',
            variant: 'alert',
            alertSeverity: 'success'
        });
    };

    const handleConfirmInvoice = async ({ reference, date, amount, remarks }) => {
        const params = {
            invoice_id: invoice.invoice_id,
            vendor_company_id: invoice.vendor_id
        };
        const headers = {
            'customer-schema': invoice.customer_schema,
            'vendor-schema': invoice.vendor_schema
        };
        const payload = {
            invoice_received_id: invoice.invoice_id,
            reference_number: reference,
            date: new Date(date).toISOString(),
            amount,
            remarks,
            user_id: userId
        };
        await confirmInvoice(payload, params, headers);
        setInvoice({ ...invoice, invoice_status_master_id: 11, invoice_status: 'Payment Confirmed' });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Payment Accepted',
            variant: 'alert',
            alertSeverity: 'success'
        });
    };

    const handleRejectInvoice = async (invoice, remarks) => {
        const params = {
            invoiceId: invoice.invoice_id,
            invoiceReceivedId: invoice.invoice_received_id,
            userId
        };
        const headers = {
            'customer-schema': invoice.customer_schema,
            'vendor-schema': invoice.vendor_schema
        };
        await rejectInvoice({ reject_remarks: remarks }, params, headers);
        setInvoice({ ...invoice, invoice_status_master_id: 14, invoice_status: 'Customer Rejected' });
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Invoice Rejected',
            variant: 'alert',
            alertSeverity: 'warning'
        });
    };

    const handleAcceptInvoice = async () => {
        const params = {
            invoice_received_id: invoice.invoice_received_id,
            user_id: userId
        };
        const headers = {
            'customer-schema': invoice.customer_schema,
            'vendor-schema': invoice.vendor_schema
        };
        await acceptInvoice({}, params, headers);
        if (invoice.invoice_status.toLowerCase() === 'WAITING FOR FINANCE APPROVAL'.toLowerCase()) {
            setInvoice({ ...invoice, invoice_status_master_id: 3, invoice_status: 'Accepted' });
        } else {
            setInvoice({ ...invoice, invoice_status_master_id: 3, invoice_status: 'Waiting for finance approval' });
        }
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'Invoice Accepted',
            variant: 'alert',
            alertSeverity: 'success'
        });
    };

    return (
        <>
            <TableRow hover key={isSales ? invoice.invoice_id : invoice.invoice_received_id}>
                <TableCell sx={{ maxWidth: 24, minWidth: 24 }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            handleOpen(isSales ? invoice.invoice_id : invoice.invoice_received_id);
                        }}
                        sx={{ padding: 0 }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {new Date(invoice.invoice_date).toLocaleDateString('en-IN', { day: '2-digit', month: 'short', year: 'numeric' })}
                </TableCell>
                <TableCell>
                    <RouterLink
                        to={
                            `/dashboard/default/${companyId}/poso/` +
                            `${isSales ? 'view-invoice' : 'view-received-invoice'}/${
                                isSales ? invoice.invoice_id : invoice.purchase_invoice_id
                            }`
                        }
                    >
                        {invoice.invoice_number}
                    </RouterLink>
                </TableCell>
                <TableCell align="right">
                    {(invoice.invoice_amount * exchangeRate).toFixed(0).replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',')}
                </TableCell>
                <TableCell>{invoice.description}</TableCell>
                {isSales ? (
                    <TableCell>
                        <Tooltip title="Edit Invoice">
                            <RouterLink
                                to={`${window.location.pathname.slice(
                                    0,
                                    window.location.pathname.search('poso')
                                )}/poso/AddInvoice/${poId}/${invoice.invoice_id}`}
                            >
                                <IconButton sx={{ p: 0 }}>
                                    <IconEdit color="purple" />
                                </IconButton>
                            </RouterLink>
                        </Tooltip>
                        <Tooltip title="Send Invoice">
                            <IconButton onClick={handleSendInvoice} color="secondary" sx={{ p: 0 }}>
                                <IconSend color="purple" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View payments">
                            <IconButton
                                onClick={() => {
                                    setModalOpen(true);
                                }}
                                sx={{ p: 0 }}
                            >
                                <PaymentIcon />
                            </IconButton>
                        </Tooltip>
                        <PaymentsViewModal
                            open={modalOpen}
                            setOpen={setModalOpen}
                            handleInvoice={handleConfirmInvoice}
                            invoiceId={invoice.invoice_id}
                            totalAmount={invoice.invoice_total}
                        />
                    </TableCell>
                ) : (
                    <TableCell>
                        <ButtonGroup disableElevation aria-label="accept reject buttons">
                            <Tooltip title="Accept Invoice" arrow>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    aria-label="Accept Invoice"
                                    onClick={handleAcceptInvoice}
                                    disabled={!invoice.is_accept_allowed}
                                >
                                    <DoneIcon color={invoice.is_accept_allowed ? 'success' : ''} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Reject Invoice" arrow>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    aria-label="Reject Invoice"
                                    onClick={() => {
                                        setRejectOpen(true);
                                    }}
                                    disabled={!invoice.is_reject_allowed}
                                >
                                    <CloseIcon color={invoice.is_reject_allowed ? 'error' : ''} />
                                </IconButton>
                            </Tooltip>
                            <RejectModal open={openReject} invoice={invoice} setOpen={setRejectOpen} handleReject={handleRejectInvoice} />
                            <Tooltip title="Pay Invoice" arrow>
                                <Button
                                    onClick={() => {
                                        setModalOpen(true);
                                    }}
                                    sx={{ p: 0 }}
                                    variant="text"
                                    disabled={!invoice.is_payment_allowed}
                                >
                                    Pay
                                </Button>
                            </Tooltip>
                            <PaymentModal
                                open={modalOpen}
                                setOpen={setModalOpen}
                                handleInvoice={handleClaim}
                                invoiceReceivedId={invoice.invoice_received_id}
                                totalAmount={invoice.invoice_total}
                            />
                        </ButtonGroup>
                    </TableCell>
                )}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <TableContainer>
                            <TableHead>
                                <TableRow>
                                    {InvoiceItemsBasicHeaders.map((header, index) => (
                                        <TableCell key={index} align={header.align ? header.align : 'inherit'}>
                                            {header.title}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoiceItems &&
                                    invoiceItems.map((invoiceItem) => (
                                        <TableRow>
                                            <TableCell>{invoiceItem.material_description}</TableCell>
                                            <TableCell align="right">
                                                {invoiceItem.rate_per_unit.toFixed(0).replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',')}
                                            </TableCell>
                                            <TableCell align="right">
                                                {invoiceItem.quantity.toFixed(0).replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',')}
                                            </TableCell>
                                            <TableCell align="right">{`${invoiceItem.tax}%`}</TableCell>
                                            <TableCell align="right">
                                                {(
                                                    invoiceItem.rate_per_unit *
                                                    exchangeRate *
                                                    invoiceItem.quantity *
                                                    (1 + invoiceItem.tax / 100)
                                                )
                                                    .toFixed(0)
                                                    .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',')}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </TableContainer>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

InvoiceRow.propTypes = {
    invoice: PropTypes.object,
    poId: PropTypes.number,
    isSales: PropTypes.bool,
    exchangeRate: PropTypes.number,
    companyId: PropTypes.number,
    userId: PropTypes.number
};
const InvoiceListItem = ({ invoices, poId, isSales, exchangeRate, companyId }) => {
    const [user, setUser] = useState();

    useEffect(() => {
        (async () => {
            setUser(await getCurrentUser());
        })();
    }, []);

    return (
        <Table size="small" aria-label="purchases">
            <TableHead>
                <TableRow>
                    <TableCell />
                    {invoiceBasicHeaders.map((header, index) => (
                        <TableCell key={index} align={header.align ? header.align : 'inherit'}>
                            {header.title}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {invoices &&
                    user &&
                    invoices.map((invoice) => (
                        <InvoiceRow
                            key={isSales ? invoice.invoice_id : invoice.invoice_received_id}
                            invoice={invoice}
                            poId={poId}
                            isSales={isSales}
                            exchangeRate={exchangeRate}
                            companyId={companyId}
                            userId={user.user_id}
                        />
                    ))}
            </TableBody>
        </Table>
    );
};
InvoiceListItem.propTypes = {
    invoices: PropTypes.array,
    poId: PropTypes.number,
    isSales: PropTypes.bool,
    exchangeRate: PropTypes.number,
    companyId: PropTypes.string
};

export default InvoiceListItem;
