export default {
    url: {
        reco: 'https://api.kredo.in:7980/api',
        ums: 'https://api.kredo.in:7900',
        ui: 'https://refactoring.d207goeuglm4vc.amplifyapp.com',
        gstReco: 'https://api.kredo.in:7981/gst',
        gstApi: 'https://api.kredo.in:7981',
        poso: 'https://api.kredo.in:7982'
    }
};
