import production from './production_config';
import staging from './staging_config';
import local from './local_config';
import test from './test_config';

const config_mapping = {
    test,
    production,
    staging,
    local
};

const env = process.env.REACT_APP_APP_ENV || 'local';
export const constants = config_mapping[env];
