const PATHNAME = window.location.pathname.slice(0, window.location.pathname.search('full-version-1'));
const QUERY_PARAMS = window.location.search;

const RecoSidebarConf = {
    id: 'ReconciliationSidebar',
    title: 'Reconciliation',
    url: PATHNAME + QUERY_PARAMS,
    type: 'group',
    children: [
        {
            id: 'home',
            title: 'Home',
            type: 'item',
            url: `${PATHNAME}full-version-1/home/${QUERY_PARAMS}`
        },
        {
            id: 'vendor',
            title: 'Reconciliation',
            type: 'collapse',
            children: [
                {
                    id: 'vendorReco',
                    title: 'Latest Recos',
                    type: 'item',
                    url: `${PATHNAME}full-version-1/vendor/latest-recos/vendorReco/${QUERY_PARAMS}`,
                    breadcrumbs: false
                },
                {
                    id: 'communication-templates',
                    title: 'Communication Templates',
                    type: 'item',
                    url: `${PATHNAME}full-version-1/communication-template/${QUERY_PARAMS}`,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'gst',
            title: 'GST Reconciliation',
            type: 'collapse',
            children: [
                {
                    id: 'gst_reco',
                    title: 'Latest Recos',
                    type: 'item',
                    url: `${PATHNAME}full-version-1/vendor/latest-recos/gst_reco/${QUERY_PARAMS}`,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'tax',
            title: 'TAX Reconciliation',
            type: 'collapse',
            children: [
                {
                    id: 'tax-latest-recos',
                    title: 'Latest Recos',
                    type: 'item',
                    url: `${PATHNAME}full-version-1/vendor/latest-recos/taxReco/${QUERY_PARAMS}`,
                    breadcrumbs: false
                }
            ]
        }
    ]
};
export default RecoSidebarConf;
