import React, { useEffect, useState, useContext } from 'react';

import { useParams } from 'react-router-dom';

import { Grid } from '@mui/material';

import TransactionTable from './TransactionTable';

import { getPOSOSchema } from '../NetworkCalls/UMS';

import CircularProgress from '@mui/material/CircularProgress';
import { DatabaseNameContext } from 'views/poso/contexts/DatabaseNameContext';

const Dashboard = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { companyId } = useParams();
    const [header, setHeader] = useState();
    const headerContext = useContext(DatabaseNameContext);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            if (companyId !== undefined) {
                sessionStorage.setItem('companyId', companyId);
                setHeader({
                    'company-id': companyId
                });
                headerContext.updateHeaderObject({
                    'company-id': companyId
                });
            }
            setIsLoading(false);
        })();
    }, [companyId]);
    return (
        <>
            {isLoading ? (
                <CircularProgress variant="determinate" />
            ) : (
                <Grid container spacing={3}>
                    {!isLoading && (
                        <>
                            <Grid item xs={12}>
                                {header && <TransactionTable title="Sales" header={header} />}
                            </Grid>
                            <Grid item xs={12}>
                                {header && <TransactionTable title="Purchases" header={header} />}
                            </Grid>
                            <script type="text/javascript" src="https://form.jotform.com/jsform/231452485462457">
                                Form
                            </script>
                        </>
                    )}
                </Grid>
            )}
        </>
    );
};

export default Dashboard;
