const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '/stage',
    defaultPath: '/dashboard/default',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    i18n: 'en',
    rtlLayout: false,
    jwt: {
        secret: 'SECRET-KEY',
        timeout: '1 days'
    },
    firebase: {
        apiKey: 'AIzaSyBernKzdSojh_vWXBHt0aRhf5SC9VLChbM',
        authDomain: 'berry-material-react.firebaseapp.com',
        projectId: 'berry-material-react',
        storageBucket: 'berry-material-react.appspot.com',
        messagingSenderId: '901111229354',
        appId: '1:901111229354:web:a5ae5aa95486297d69d9d3',
        measurementId: 'G-MGJHSL8XW3'
    },
    auth0: {
        client_id: 'HvYn25WaEHb7v5PBT7cTYe98XATStX3r',
        domain: 'demo-localhost.us.auth0.com'
    },
    paths: [
        '/dashboard/default/:id',
        '/dashboard/default/create-profile/:id',
        '/dashboard/default/invite-users/:id',
        '/dashboard/default/invite-user-form/:id',
        '/dashboard/default/:id/reconciliation/vendor-customer',
        '/dashboard/default/customer-vendor-details/:id',
        '/dashboard/default/invite-company/:id',
        '/dashboard/default/:id/reconciliation/vendor-customer/full-version-1/:recoType/summary/:recoIterationId/:getFrom',
        '/dashboard/default/:id/reconciliation/vendor-customer/full-version-1/:recoType/summary/:recoId/:getFrom/:status/:category/:remark/:remark2',
        '/dashboard/default/:id/reconciliation/rules',
        '/dashboard/default/:id/reconciliation/programs',
        '/dashboard/default/:id/reconciliation/vendor-customer/full-version-1/:recoId/:status/:category/:remark/:remark2',
        '/dashboard/default/:id/reconciliation/vendor-customer/full-version-1/home/',
        '/dashboard/default/:id/reconciliation/vendor-customer/full-version-1/gst/gst-latest-recos/',
        '/dashboard/default/:id/reconciliation/vendor-customer/full-version-1/vendor/latest-recos/:recoType/',
        '/dashboard/default/:id/reconciliation/vendor-customer/full-version-1/vendor/shared-recos/',
        '/reco-dashboard/:id',
        '/dashboard/default/:id/reconciliation/vendor-customer/full-version-1/vendor/projects/',
        '/dashboard/default/:id/reconciliation/vendor-customer/full-version-1/vendor/projects/:projectId',
        '/dashboard/default/:id/reconciliation/vendor-customer/full-version-1/vendor/latest-recos/history/:recoId/',
        '/dashboard/default/:id/poso/DashBoard',
        '/dashboard/default/:id/poso/tables/list-po',
        '/dashboard/default/:id/poso/approve-po',
        '/dashboard/default/:id/poso/tables/confirmed-payments',
        '/dashboard/default/:id/poso/tables/pending-payments',
        '/dashboard/default/:id/poso/ChatOfAccounts',
        '/dashboard/default/:id/poso/reports',
        '/dashboard/default/:id/poso/AddPo',
        '/dashboard/default/:id/poso/AddInvoice',
        '/dashboard/default/:id/poso/AddInvoice/:SalesPOId',
        '/dashboard/default/:id/poso/view-po/:poId',
        '/dashboard/default/:id/poso/DashBoardPo/TimeLine',
        '/dashboard/default/:id/poso/approve-po/:id',
        '/dashboard/default/:id/poso/tables/payable',
        '/dashboard/default/:id/poso/payments/received',
        '/dashboard/default/:id/poso/tables/raised',
        '/dashboard/default/:id/poso/po/received',
        '/dashboard/default/:id/poso/invoice/raised',
        '/dashboard/default/:id/poso/invoice/received',
        '/dashboard/default/:id/poso/tables/payable',
        '/dashboard/default/:id/poso/payments/received',
        '/dashboard/default/:id/poso/ChatOfAccounts',
        '/dashboard/default/:id/poso/reports/report-1',
        '/dashboard/default/:id/poso/reports/report-2',
        '/dashboard/default/:id/poso/settings',
        '/dashboard/default/:id/poso/edit-po/:poId',
        '/dashboard/default/:id/reconciliation/vendor-customer/full-version-1/communication-template',
        '/dashboard/default/:id/reconciliation/vendor-customer/full-version-1/vendor/history/:recoId/',
        '/dashboard/default/:id/reconciliation/vendor-customer/full-version-1/tags/:recoId/:tagId/',
        '/dashboard/default/:id/reconciliation/vendor-customer/full-version-1/vendor/old-recos/',
        '/dashboard/default/:id/poso/invoice/approve',
        '/dashboard/default/:companyId/poso/view-invoice-approval/:invoiceReceivedId',
        '/dashboard/default/:companyId/poso/approve-po/:id',
        '/dashboard/default/:companyId/poso/view-received-invoice/:invoiceReceivedId',
        '/dashboard/default/:companyId/poso/view-invoice/:invoiceId',
        '/dashboard/default/:companyId/poso/view-received-po/:poReceivedId',
        '/dashboard/default/:companyId/poso/view-po/:poId',
        '/dashboard/default/:companyId/poso/AddInvoice/:salesPOId',
        '/dashboard/default/:companyId/poso/EditInvoice/:salesPOId/:invoiceId',
        '/dashboard/default/:companyId/poso/tables/raised',
        '/dashboard/default/:companyId/poso/po/received',
        '/dashboard/default/:companyId/poso/invoice/raised',
        '/dashboard/default/:companyId/poso/invoice/received',
        // poso paths
        '/dashboard/default/:companyId/poso/po',
        '/dashboard/default/:companyId/poso/view-purchase-invoice/:purchaseInvoiceId'
    ]
};

export default config;
