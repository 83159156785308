import { matchPath } from 'react-router-dom';

import jwtDecode from 'jwt-decode';

const token = window.localStorage.getItem('serviceToken');

export const getCompanyId = (paths, length) => {
    let companyId;
    // eslint-disable-next-line
    for (let i = 0; i < length; i++) {
        const match = matchPath(paths[i], window.location.pathname);
        if (match && match.params.id) {
            companyId = match.params.id;
            break;
        }
    }

    return companyId;
};

export const makeId = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const generate_file_name = (file_name) => {
    const random_characters = makeId(8);
    const file_name_arr = file_name.split('.');
    file_name = `${file_name_arr[0]}_${random_characters}.${file_name_arr[1]}`;

    return file_name;
};

export const fileValidator = (fileName, supported_extensions = /(\.xlsx|\.xls|\.xlsm)$/i) => {
    if (!supported_extensions.exec(fileName)) {
        return false;
    }
    return true;
};
