import { useDispatch, useSelector } from 'react-redux';
import { getCompanyId } from 'utils/helper';
import useAuth from 'hooks/useAuth';
import config from 'config';

const useCompanyIdFunc = () => {
    const compId = getCompanyId(config.paths, config.paths.length);
    const dispatch = useDispatch();
    const companyId = useSelector((state) => state.account.companyId);
    const { user, userPermissions } = useAuth();

    if (companyId !== compId) {
        dispatch({ type: 'COMPANY_LOGIN', payload: { companyId: compId, user, userPermissions } });
    }

    return companyId;
};

export default useCompanyIdFunc;
