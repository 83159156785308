import React from 'react';

import { IconLink, IconSearch } from '@tabler/icons';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Button, ListItemIcon, ListItemText, IconButton, Menu, MenuItem, Input } from '@material-ui/core';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

const useStyles = makeStyles({
    icon: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
});

const QuickLinksSection = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                id="quick-button"
                aria-controls="quick-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <IconLink color={theme.palette.secondary.main} />
            </IconButton>
            <Menu id="quick-menu" aria-labelledby="quick-button" anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <IconSearch fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        <Input type="text" placeholder="search" fullWidth />
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <BusinessCenterIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Recon - BPU & Co</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <BusinessCenterIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Compliance - Kredo Analytics Private Limited</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <BusinessCenterIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Expense - BPU & Co</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default QuickLinksSection;
