import axios from 'axios';
import { constants } from 'consts';
import { getCompanyId } from 'utils/helper';
import config from 'config';

const {
    url: { ums: UMS_URL }
} = constants;

const getDefaultHeaders = async () => {
    const companyId = getCompanyId(config.paths, config.paths.length);
    const url = `${UMS_URL}/companies/poso-schema-data/${companyId}/`;
    if (sessionStorage.getItem('database') && sessionStorage.getItem('schema')) {
        return {
            'x-dts-database': sessionStorage.getItem('database') && sessionStorage.getItem('database').toLowerCase(),
            'x-dts-schema': sessionStorage.getItem('schema') && sessionStorage.getItem('schema').toLowerCase(),
            schema: sessionStorage.getItem('schema') && sessionStorage.getItem('schema').toLowerCase(),
            'customer-schema': sessionStorage.getItem('schema') && sessionStorage.getItem('schema').toLowerCase()
        };
    }
    let headerObject;
    try {
        const { data } = await axios.get(url);
        headerObject = { schemaName: data.db_schema_prefix + '.' + data.db_tool_prefix, database: data.database };
    } catch (err) {
        console.log(err);
    }
    return {
        'x-dts-database': headerObject.database.toLowerCase(),
        'x-dts-schema': headerObject.schemaName.toLowerCase(),
        schema: headerObject.schemaName.toLowerCase(),
        customer_schema: headerObject.schemaName.toLowerCase()
    };
};

export const getWrapper = async (url, headers) => {
    let data;
    const defaultHeaders = await getDefaultHeaders();
    try {
        const response = await axios.get(url, { headers: { ...defaultHeaders, ...headers } });
        data = response.data;
    } catch (err) {
        console.log(err);
    }
    return data;
};

export const getWrapperNew = async (url, headers) => {
    const defaultHeaders = await getDefaultHeaders();
    return axios.get(url, { headers: { ...defaultHeaders, ...headers } });
};

export const postWrapper = async (url, headers, payload) => {
    let data;
    const defaultHeaders = await getDefaultHeaders();
    try {
        const response = await axios.post(url, payload, { headers: { ...defaultHeaders, ...headers } });
        data = response.data;
    } catch (err) {
        console.log(err);
    }
    return data;
};

export const deleteWrapper = async (url, headers) => {
    let data;
    const defaultHeaders = await getDefaultHeaders();
    try {
        const response = await axios.delete(url, { headers: { ...defaultHeaders, ...headers } });
        data = response.data;
    } catch (err) {
        console.log(err);
    }
    return data;
};

export const patchWrapper = async (url, headers, payload) => {
    let response;
    const defaultHeaders = await getDefaultHeaders();
    try {
        response = await axios.patch(url, payload, { headers: { ...defaultHeaders, ...headers } });
    } catch (err) {
        console.log(err);
    }
    return response;
};
