import React, { useState } from 'react';

import DataTable from 'react-data-table-component';

import PropTypes, { array } from 'prop-types';

import { Box } from '@mui/material';

// Project imports
import MainCard from 'ui-component/cards/MainCard';

// Material UI imports
import { useTheme } from '@mui/material/styles';

const DataTableActionable = ({ columns, data, title, totalRows, refetchData, isLoading }) => {
    const theme = useTheme();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState([].push(data));
    const [rows, setRows] = useState(data.length > perPage ? data.slice(perPage) : data);

    const handlePageChange = (page) => {
        if (page * perPage <= data.length) {
            setRows(data.slice((page - 1) * perPage, (page - 1) * perPage + perPage));
            setCurrentPage(page);
        } else {
            refetchData(data.length);
            setCurrentPage(page);
        }
    };
    const handlePerRowsChange = (newPerPage, page) => {
        if (page * perPage <= data.length) {
            setRows(data.slice((page - 1) * newPerPage, (page - 1) * perPage + newPerPage));
            setCurrentPage(page);
            setPerPage(newPerPage);
        } else {
            refetchData(data.length);
            setCurrentPage(page);
            setPerPage(newPerPage);
        }
    };

    return (
        <MainCard content={false} title={title}>
            <Box
                sx={{
                    height: 500,
                    width: '100%',
                    '& .MuiDataGrid-root': {
                        border: 'none',
                        '& .MuiDataGrid-cell': {
                            borderColor: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
                        },
                        '& .MuiDataGrid-columnsContainer': {
                            color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
                            borderColor: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
                        },
                        '& .MuiDataGrid-columnSeparator': {
                            color: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
                        }
                    }
                }}
            >
                {data && (
                    <DataTable
                        columns={columns}
                        data={rows}
                        dense
                        direction="auto"
                        fixedHeader
                        fixedHeaderScrollHeight="400px"
                        highlightOnHover
                        pagination
                        paginationServer
                        progressPending={isLoading}
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        responsive
                    />
                )}
            </Box>
        </MainCard>
    );
};
DataTableActionable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    title: PropTypes.string,
    totalRows: PropTypes.number,
    isLoading: PropTypes.bool,
    refetchData: PropTypes.func
};
export default DataTableActionable;
