import { getWrapper, postWrapper, patchWrapper, deleteWrapper } from './Wrapper';
import { constants } from 'consts';

const {
    url: { poso: BASE_URL }
} = constants;

function getUrlParams(url, params) {
    params = Object.entries(params)
        .map((item) => item[0] + '=' + item[1])
        .join('&');
    return `${url}${params}`;
}

export const getPayments = async (headers) => {
    const url = `${BASE_URL}/payment/`;
    const response = await getWrapper(url, headers);
    return response;
};

export const getPaymentsAdvance = async (params) => {
    const url = getUrlParams(`${BASE_URL}/payment/advance/list/?`, params);
    console.log(params, url);
    const response = await getWrapper(url, {});
    return response;
};

export const getPaymentsReceived = async (headers) => {
    const url = `${BASE_URL}/payment/collections/`;
    const response = await getWrapper(url, headers);
    return response;
};

export const createPayment = async (payload, headers) => {
    const url = `${BASE_URL}/payment/`;
    const response = await postWrapper(url, headers, payload);
    return response;
};

export const acceptPayment = async (payload, params, headers) => {
    const url = `${BASE_URL}payment/received/accept/?payment_id=${params.paymentId}&payment_received_id=${params.paymentReceivedId}`;
    const response = await patchWrapper(url, headers, payload);
    return response;
};

export const rejectPayment = async (payload, params, headers) => {
    const url = `${BASE_URL}payment/received/reject/?payment_id=${params.paymentId}&payment_received_id=${params.paymentReceivedId}`;
    const response = await patchWrapper(url, headers, payload);
    return response;
};

export const getPaymentsByInvoiceReceivedId = async (invoiceReceivedId) => {
    const url = `${BASE_URL}/payment/?invoice_received_id=${invoiceReceivedId}`;
    const response = await getWrapper(url);
    return response;
};

export const getPaymentsReceivedByInvoiceId = async (invoiceId) => {
    const url = `${BASE_URL}/payment/received/?invoice_id=${invoiceId}`;
    const response = await getWrapper(url);
    return response;
};
