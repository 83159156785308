import { getWrapper, postWrapper, deleteWrapper, patchWrapper, getWrapperNew } from './Wrapper';
import { constants } from 'consts';

const {
    url: { ums: UMS_URL, poso: BASE_URL }
} = constants;

export const getInvoicesReceived = async (poId, headers) => {
    const url = `${BASE_URL}/invoices/purchase/?po_id=${poId}`;
    const response = await getWrapper(url, headers);
    console.log(response, 'result');
    return response;
};

export const getInvoices = async (poId, headers) => {
    const url = `${BASE_URL}/invoices/raised/?sales_po_id=${poId}`;
    const response = await getWrapper(url, headers);
    return response;
};
export const getInvoiceItems = async (invoiceId, headers) => {
    const url = `${BASE_URL}/invoices/items/?invoice_id=${invoiceId}`;
    const response = await getWrapper(url, headers);
    return response;
};

export const getInvoiceReceivedItems = async (invoiceReceivedId, headers) => {
    const url = `${BASE_URL}/invoices/received/items/?invoice_received_id=${invoiceReceivedId}`;
    const response = await getWrapper(url, headers);
    return response;
};

export const getInvoicesByPoReceivedId = async (poReceivedId, headers) => {
    const url = `${BASE_URL}/invoices/?sales_po_id=${poReceivedId}`;
    const response = await getWrapper(url, headers);
    return response;
};

export const getInvoiceById = async (invoiceId, headers) => {
    const url = `${BASE_URL}/invoices/${invoiceId}/`;
    const response = await getWrapper(url, headers);
    return response;
};

export const getInvoiceReceivedById = async (invoiceReceivedId, headers) => {
    const url = `${BASE_URL}/invoices/purchase/${invoiceReceivedId}/`;
    const response = await getWrapper(url, headers);
    return response;
};

export const deleteInvoiceItemById = async (invoiceItemId, headers) => {
    const url = `${BASE_URL}/invoices/items/${invoiceItemId}/`;
    const response = await deleteWrapper(url, headers);
    return response;
};

export const getInvoiceItemById = async (invoiceItemId, headers) => {
    const url = `${BASE_URL}/invoices/items/${invoiceItemId}/`;
    const response = await getWrapper(url, headers);
    return response;
};

export const addInvoiceItem = async (payload, headers) => {
    const url = `${BASE_URL}/invoices/items/`;
    const response = await postWrapper(url, headers, payload);
    return response;
};

export const updateInvoiceItem = async (invoiceItemId, payload, headers) => {
    const url = `${BASE_URL}/invoices/items/${invoiceItemId}/`;
    const response = await patchWrapper(url, headers, payload);
    return response;
};

export const addInvoiceAttachment = async (invoiceId, payload, headers) => {
    const url = `${BASE_URL}/invoices/attachments/?invoice_id=${invoiceId}`;
    const response = await postWrapper(url, headers, payload);
    return response;
};

export const getInvoiceAttachmentById = async (invoiceAttachmentId, headers) => {
    const url = `${BASE_URL}/invoices/attachments/${invoiceAttachmentId}/`;
    const response = await getWrapper(url, headers);
    return response;
};

export const getInvoiceAttachmentReceivedById = async (invoiceAttachmentId, headers) => {
    const url = `${BASE_URL}/invoices/attachments/received/${invoiceAttachmentId}/`;
    const response = await getWrapper(url, headers);
    return response;
};

export const addInvoice = async (payload, headers) => {
    const url = `${BASE_URL}/invoices/`;
    const response = await postWrapper(url, headers, payload);
    return response;
};

export const updateInvoice = async (invoiceId, payload, headers) => {
    const url = `${BASE_URL}/invoices/${invoiceId}/`;
    const response = await patchWrapper(url, headers, payload);
    return response;
};

export const newSendInvoice = async (invoiceId, userId, approvalInformationId, payload, params, headers) => {
    const url = `${BASE_URL}/invoices/send/?invoice_id=${invoiceId}&&user_id=${userId}&&approval_information_id=${approvalInformationId}`;
    const response = await postWrapper(url, headers, params, payload);
    return response;
};
export const sendInvoice = async (invoiceId, userId, approvalInformationId, payload, params, headers) => {
    const url = `${BASE_URL}/invoices/send/?invoice_id=${invoiceId}&&user_id=${userId}&&approval_information_id=${approvalInformationId}`;
    const response = await postWrapper(url, headers, payload);
    return response;
};

export const claimReceivedInvoice = async (payload, params, headers) => {
    params = Object.entries(params)
        .map((item) => item[0] + '=' + item[1])
        .join('&');
    const url = `${BASE_URL}/invoices/received/claim/?${params}`;
    const response = await patchWrapper(url, headers, payload);
    return response;
};

export const confirmInvoice = async (payload, params, headers) => {
    params = Object.entries(params)
        .map((item) => item[0] + '=' + item[1])
        .join('&');
    const url = `${BASE_URL}/invoices/confirm/?${params}`;
    const response = await patchWrapper(url, headers, payload);
    return response;
};

export const acceptInvoice = async (payload, params, headers) => {
    params = Object.entries(params)
        .map((item) => item[0] + '=' + item[1])
        .join('&');
    const url = `${BASE_URL}/invoices/received/accept/?${params}`;
    const response = await patchWrapper(url, headers, payload);
    return response;
};

export const rejectInvoice = async (payload, params, headers) => {
    const url = `${BASE_URL}/invoices/received/reject/?purchase_invoice_id=${params.purchase_invoice_id}&user_id=${params.userId}`;
    const response = await patchWrapper(url, headers, payload);
    return response;
};

export const getInvoiceByStatus = async (invoiceStatus) => {
    const url = `${BASE_URL}/invoices/by_status?invoice_status=${invoiceStatus}`;
    const response = await getWrapper(url);
    return response;
};

export const getInvoicesRaised = async () => {
    const url = `${BASE_URL}/invoices/raised/`;
    const response = await getWrapper(url);
    return response;
};

export const getInvoicesRaisedBySalesPoId = async (params) => {
    const url = `${BASE_URL}/invoices/raised/?sales_po_id=${params.sales_po_id}`;
    const response = await getWrapper(url);
    return response;
};

export const getInvoicesReceivedTable = async () => {
    const url = `${BASE_URL}/invoices/purchase/`;
    const response = await getWrapper(url);
    return response;
};
// /invoices/all_received/

export const getAllInvoicesReceived = async () => {
    const url = `${BASE_URL}/invoices/all_received/`;
    const response = await getWrapper(url);
    return response;
};

export const getAllInvoicesReceivedPendingProcurement = async (userId) => {
    const url = `${BASE_URL}/invoices/pending/approval_procurement/?user_id=${userId}`;
    const response = await getWrapper(url);
    return response;
};

export const getInvoicesReceivedNew = (poId) => {
    const url = `${BASE_URL}/invoices/purchase/${poId}`;
    return getWrapper(url);
};

export const getInvoiceAdditionalChargesMaster = () => {
    const url = `${BASE_URL}/invoices/additional_charge_master/`;
    return getWrapper(url);
};

export const createInvoiceAdditionalCharge = (payload) => {
    const url = `${BASE_URL}/invoices/additional_charge/`;
    return postWrapper(url, {}, payload);
};
